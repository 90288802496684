import React from "react";
import FormInputText from "../Form/FormInputText";
import { Password } from "primereact/password";
import { AddImage } from "./AddImage";
import FormPasswordHeader from "../Form/FormPasswordHeader";
import FormPasswordFooter from "../Form/FormPasswordFooter";
import {
  getFormErrorMessage,
  isFormFieldValid,
} from "./FormValidationNewPlayer";
import classNames from "classnames";
import "../../styles/SearchBar.css";

const PlayerUserFields = (props) => {
  const isUserAndPassShowed = false;
  return (
    <div>
      {/* TO DO IMAGE WITH PROPS */}
      <AddImage
        value={props.profilePicValue}
        setImage={props.setProfilePic}
        label={
          props.addNew ? "Adaugati poza de profil" : "Schimbati poza de profil"
        }
      />
      {props.addNew && isUserAndPassShowed && (
        <FormInputText
          name="username"
          label="Nume utilizator*"
          value={props.values.username}
          onChange={props.onChange}
          errors={getFormErrorMessage("username", props.touched, props.errors)}
          formFieldValid={isFormFieldValid(
            "username",
            props.touched,
            props.errors
          )}
        />
      )}
      {props.addNew && isUserAndPassShowed && (
        <div className="p-field">
          <span>
            <label
              htmlFor="password"
              className={classNames({
                "p-error": isFormFieldValid(
                  "password",
                  props.touched,
                  props.errors
                ),
              })}
            >
              Parola*
            </label>
            <Password
              id="password"
              name="password"
              value={props.values.password}
              onChange={props.onChange}
              toggleMask
              className={classNames({
                "p-invalid": isFormFieldValid(
                  "password",
                  props.touched,
                  props.errors
                ),
              })}
              header={<FormPasswordHeader />}
              footer={<FormPasswordFooter />}
            />
          </span>
          {getFormErrorMessage("password", props.touched, props.errors)}
        </div>
      )}
    </div>
  );
};
export default PlayerUserFields;
