import React from "react";
import { Button } from "primereact/button";

const EditPlayer = (props) => {
  return (
    <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-success mr-2"
      onClick={props.onClick}
    />
  );
};

export default EditPlayer;
