import React, {useState, useRef} from 'react'
import {useFormik} from "formik";
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {ConfirmPopup} from 'primereact/confirmpopup';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Dialog} from "primereact/dialog";
import {Password} from "primereact/password";
import {InputText} from "primereact/inputtext";
import {showWarnWithMessage, showSuccess, showError} from "../services/toast.service";
import {
    changeUserPassword,
    createUserTeamMap,
    deleteAllOfUser,
    deleteUser,
    updateAcademy_Leader,
    updateAdmin,
    updateCoach,
    updatePhysical_Trainer,
    updatePsychologist
} from '../services/administration.service';
import { getAllPersonsWithUsersIds } from '../services/getPersonsByUserId.service';
import { getAllUserTeamMapOfUserId } from '../services/administration.service';
import {isNullOrEmpty} from "../services/array.service";
import {checkPassword} from './Form/CheckPassword';
import FormPasswordFooter from './Form/FormPasswordFooter';
import FormPasswordHeader from './Form/FormPasswordHeader';
import FormMultiSelect from './Form/FormMultiSelect';
import FormInputText from './Form/FormInputText';
import {isFormFieldValid, getFormErrorMessage, isFormValid} from './Form/FormValidationNewUser';
import FormDropdown from './Form/FormDropdown';
import DropdownCalendar from './Form/FormCalendar';
import FormAccordionTabHeader from './Form/FormAccordionTabHeader';
import {Nationalities} from './Form/FormNationalities';
import {getNationality} from './Form/FormGetNationality';
import {areFieldsValid} from './Form/FormValidationNewUser';
import AddImage from './PlayerPage/AddImage';
import {SetPlayerErrors} from "./PlayerPage/SetPlayerErrors";
import classNames from 'classnames';
import moment from 'moment';
import ls from 'local-storage';
import '../styles/UserCard.css';
import '../styles/Common.css';

export default function UserCard(props) {
    let username = props.userData.username;
    const [visibleDelDlg, setVisibleDelDlg] = React.useState(false);
    const [visibleViewDlg, setVisibleViewDlg] = React.useState(false);
    const [birthdate, setBirthdate] = React.useState(null);
    const [selectedTeams, setSelectedTeams] = React.useState(null);
    const [profilePic, setProfilePic] = React.useState("https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png");
    const [newPassword, setNewPassword] = React.useState();
    const [showNewPwDlg, setShowNewPwDlg] = React.useState(false);
    const isInitialMount = useRef(true);
    const [editMode, setEditMode] = React.useState(false);

    const getRoleDescription = (role_id) => 
    {
        if(props.allRoles !== null && props.allRoles !== undefined)
        {
            if(props.allRoles.find(role => role.role_id === role_id) !== undefined)
                return props.allRoles.find(role => role.role_id === role_id).role_desc;
        }
    }

    const getActiveStatusDescription = (active_id) => 
    {
        if(props.allActiveStatus !== null && props.allActiveStatus !== undefined)
        {
            if(props.allActiveStatus.find(status => status.active_id === active_id) !== undefined)
                return props.allActiveStatus.find(status => status.active_id === active_id).active_desc;
        }
    }

    const getTeamsForCurrentUser = (teamsForUser) =>
    {
        var teamsForCurrentUser = [];
        var userTeams = isNullOrEmpty(teamsForUser) ? [] : teamsForUser;

        props.allTeams.forEach(team => 
            userTeams.forEach(userTeam=>{
                if(userTeam === team.team_id)
                {
                    teamsForCurrentUser.push(team);
                }
            })
        );
        
        return teamsForCurrentUser;
    }

    React.useEffect(() => {
        if(isInitialMount.current){
            isInitialMount.current = false;
            const teams = [];
            getAllUserTeamMapOfUserId(ls.get('token'), props.userData.user_id)
            .then(res => {
                if (res === null || res === undefined || res === '') {
                } else {
                    res.forEach(item => {
                        teams.push(item.team_id);
                    });
                    setSelectedTeams(getTeamsForCurrentUser(teams));
                }
            }).catch(err => {
            console.log(err);
            })
        }
        else
        {
            const teams = [];
            getAllUserTeamMapOfUserId(ls.get('token'), props.userData.user_id)
            .then(res => {
                if (res === null || res === undefined || res === '') {
                    setSelectedTeams([]);
                } else {
                    res.forEach(item => {
                        teams.push(item.team_id);
                    });
                    if(JSON.stringify(getTeamsForCurrentUser(teams)) !== JSON.stringify(selectedTeams)){
                        setSelectedTeams(getTeamsForCurrentUser(teams));
                        }
                }
            }).catch(err => {
            console.log(err);
            })
        }
    }, [editMode]);

    const formFields = {
        username: props.userData.username,
        active_id: {active_id: props.userData.active_id, active_desc: getActiveStatusDescription(props.userData.active_id)},
        roleId: {role_id: props.userData.role_id, role_desc: getRoleDescription(props.userData.role_id)},
        firstname: props.personData.firstname,
        lastname: props.personData.lastname,
        email: props.personData.email,
        phone: props.personData.phone,
        pic_cnp: props.personData.pic_cnp,
        nationality: getNationality(props.personData.nationality),
        full_address: props.personData.full_address,
        birthdate: props.personData.birthdate,
        licence: props.professionalDetails.licence,
        team_history: props.professionalDetails.team_history,
        teams: getTeamsForCurrentUser(selectedTeams)
      };

    const formik = useFormik({
        initialValues: formFields,
        validate: (data) => {
          return areFieldsValid(data, false, getAllPersonsWithUsersIds(props.allPersons, props.allUsers));
        },
        onSubmit: () => {
            execUpdateUser();
            setVisibleViewDlgAndResetErrors(false);
            formik.resetForm();
        },
      });
      
    const deleteFromUsersArray = () => {
        const newArray = [];
        if (!isNullOrEmpty(props.allUsers)) {
            props.allUsers.filter(item => item.user_id !== props.userData.user_id).map(item => newArray.push(item))
        }
        props.setAllUsers(newArray);
        
    }

    const deleteFromPersonsArray = () => {
        const newArray = [];
        if (!isNullOrEmpty(props.allPersons)) {
            props.allPersons.filter(item => item.user_id !== props.userData.user_id).map(item => newArray.push(item))
        }
        props.setAllPersons(newArray);
        
    }

    const execDeleteUser = () => {
        deleteUser(ls.get('token'), props.userData.user_id)
            .then(res => {
                if (res === null || res === undefined || res === '')
                    showWarnWithMessage(props.notificationRef, "Nu s-a putut sterge!");
                else {
                    showSuccess(props.notificationRef);
                    deleteFromUsersArray();
                    deleteFromPersonsArray();
                }
            }).catch(err => {
            showError(props.notificationRef);
            console.log(err);
        })
    }

    const getNewUpdatedObject = () => {
        const currentObj = props.userData;
        currentObj.active_id = formik.values.active_id.active_id;
        currentObj.active_desc = formik.values.active_id.active_desc;
        currentObj.role_id = formik.values.roleId.role_id;
        currentObj.role_desc = formik.values.roleId.role_desc;
        currentObj.firstname = formik.values.firstname;
        currentObj.lastname = formik.values.lastname;
        currentObj.email = formik.values.email;
        currentObj.phone = formik.values.phone;
        currentObj.pic_cnp = formik.values.pic_cnp;
        currentObj.nationality = formik.values.nationality.nationality_desc;
        currentObj.birthdate = birthdate != null ? moment(birthdate).format() : null;
        currentObj.full_address = formik.values.full_address;
        if (currentObj.role_id !== 5) {
            currentObj.team_history = formik.values.team_history;
            currentObj.licence = formik.values.licence;
        }
        return currentObj;
    }

    const findIndexByUserId = (arrayToSearch, user_id) => {
        let index = -1;
        for (let i = 0; i < arrayToSearch.length; i++) {
            if (arrayToSearch[i].user_id === user_id) {
                index = i;
                break;
            }
        }
        return index;
      }

    const findIndexByPersonId = (arrayToSearch, person_id) => {
    let index = -1;
    for (let i = 0; i < arrayToSearch.length; i++) {
        if (arrayToSearch[i].person_id === person_id) {
            index = i;
            break;
        }
    }
    return index;
    }

    const updateUserDetails = () => 
    {
    let _persons = [ ...props.allPersons];
    const person = {user_id: props.userData.user_id,
        person_id: props.personData.person_id,
        firstname : formik.values.firstname,
        lastname : formik.values.lastname,
        email : formik.values.email,
        phone : formik.values.phone,
        pic_cnp : formik.values.pic_cnp,
        nationality : formik.values.nationality.nationality_desc,
        birthdate : birthdate,
        full_address : formik.values.full_address}
    const index = findIndexByUserId(props.allPersons, props.userData.user_id);
    _persons[index] = person;
    props.setAllPersons(_persons);

    var allDetails = props.getAllDetails(formik.values.roleId.role_id);
    var details = {person_id: props.personData.person_id,
        team_history: formik.values.team_history,
        licence: formik.values.licence}
    const detailsIndex = findIndexByPersonId(allDetails, props.personData.person_id);
    allDetails[detailsIndex] = details;
    props.setNewDetails(formik.values.roleId.role_id, allDetails);
    
    }

    const execUpdateUser = () => {
        switch (props.userData.role_id) {
            case 1:
                updateCoach(ls.get('token'), getNewUpdatedObject())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica!");
                        else {
                            showSuccess(props.notificationRef);
                            updateUserDetails();
                            updateTeams();
                        }
                    }).catch(err => {
                    showError(props.notificationRef);
                    console.log(err);
                });
                break;
            case 2:
                updatePhysical_Trainer(ls.get('token'), getNewUpdatedObject())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica!");
                        else {
                            showSuccess(props.notificationRef);
                            updateUserDetails();
			                updateTeams();
                        }
                    }).catch(err => {
                        showError(props.notificationRef);
                        console.log(err);
                    });
                break;
            case 3:
                updatePsychologist(ls.get('token'), getNewUpdatedObject())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica!");
                        else {
                            showSuccess(props.notificationRef);
                            updateUserDetails();
			                updateTeams();
                        }
                    }).catch(err => {
                        showError(props.notificationRef);
                        console.log(err);
                    });
                break;
            case 4:
                updateAcademy_Leader(ls.get('token'), getNewUpdatedObject())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica!");
                        else {
                            showSuccess(props.notificationRef);
                            updateUserDetails();
			                updateTeams();
                        }
                    }).catch(err => {
                        showError(props.notificationRef);
                        console.log(err);
                    });
                break;
            case 5:
                updateAdmin(ls.get('token'), getNewUpdatedObject())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica!");
                        else {
                            showSuccess(props.notificationRef);
                            let _persons = [ ...props.allPersons];
                            const person = {user_id: props.userData.user_id,
                                person_id: props.personData.person_id,
                                firstname : formik.values.firstname,
                                lastname : formik.values.lastname,
                                email : formik.values.email,
                                phone : formik.values.phone,
                                pic_cnp : formik.values.pic_cnp,
                                nationality : formik.values.nationality.nationality_desc,
                                birthdate : birthdate,
                                full_address : formik.values.full_address}
                            var index = findIndexByUserId(props.allPersons, props.userData.user_id);
                            _persons[index] = person;
                            props.setAllPersons(_persons);
                        }
                    }).catch(err => {
                    showError(props.notificationRef);
                    console.log(err);
                });
                break;
        }
    }

    const header = (
        <img alt="Card" src="showcase/demo/images/usercard.png"
             style={{borderRadius: "50%", width: "50%", height: "50%"}}
             onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}/>
    );

    const setVisibleViewDlgAndResetErrors = (visible) =>{
        setEditMode(!editMode);
        SetPlayerErrors(formik.errors,"");
        formik.values.username= props.userData.username;
        formik.values.active_id= {active_id: props.userData.active_id, active_desc: getActiveStatusDescription(props.userData.active_id)};
        formik.values.roleId= {role_id: props.userData.role_id, role_desc: getRoleDescription(props.userData.role_id)};
        formik.values.firstname= props.personData.firstname;
        formik.values.lastname= props.personData.lastname;
        formik.values.email= props.personData.email;
        formik.values.phone= props.personData.phone;
        formik.values.pic_cnp= props.personData.pic_cnp;
        formik.values.nationality= getNationality(props.personData.nationality);
        formik.values.full_address= props.personData.full_address;
        formik.values.birthdate= props.personData.birthdate;
        formik.values.licence= props.professionalDetails.licence;
        formik.values.team_history= props.professionalDetails.team_history;
        formik.values.teams = getTeamsForCurrentUser(selectedTeams);
        setBirthdate(props.personData.birthdate);
        setVisibleViewDlg(visible);
    }

    const footer = (
        <span className="p-grid display-block">
                <Button id={'viewBtn_' + props.userData.user_id} label="Vizualizeaza/ Modifica"
                        onClick={() => setVisibleViewDlgAndResetErrors(true)} className="p-col-8 p-mb-2"
                        icon="pi pi-eye"/>
                <Button id={'delBtn_' + props.userData.user_id} label="Sterge" icon="pi pi-trash"
                        className="p-col-8 p-mb-2 p-button-danger "
                        onClick={() => setVisibleDelDlg(true)}/>
                <Button id={'changepw_' + props.userData.user_id} label="Schimba parola"
                        icon="pi pi-lock"
                        onClick={() => setShowNewPwDlg(true)}
                        className="p-col-8 p-button-warning"/>
            </span>
    );

    const addInTeamUserMap = (user_id) => {
        if(selectedTeams !== null && selectedTeams !== undefined){
            selectedTeams.forEach(team=> {
                createUserTeamMap(ls.get('token'), getTeamUserMapObj(user_id, team.team_id))
                    .then(res => {
                        //showSuccess(notificationRef);
                    }).catch(err => {
                        showError(props.notificationRef);
                        console.log(err);
                    })
            });
        }
    }

    const getTeamUserMapObj = (user_id, team_id) => {
        return {
            team_id: team_id,
            user_id: user_id
        }
    }

    const execChangeUserPw = () => {
        if(isPasswordValid(changePwObj().password))
        {
            changeUserPassword(ls.get('token'), changePwObj())
            .then(res => {
                if (res.data === null || res.data === undefined || res.data === '') {
                    showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica parola!");
                } else {
                    changeUserPwInAllUserArray();
                    showSuccess(props.notificationRef);
                }
            }).catch(err => {
                showError(props.notificationRef);
                console.log(err);
            })
        }
        else
        {
            showWarnWithMessage(props.notificationRef, "Nu s-a putut modifica parola. " + passwordError);
        }
       
    }

    const changeUserPwInAllUserArray = () => {
        const newArray = props.allUsers;

        newArray.forEach(user => {
            if (user.username === username) {
                user.password = newPassword;
                props.setAllUsers(newArray);
                return;
            }
        })
    }

    const changePwObj = () => {
        return {
            username: username,
            password: newPassword
        }
    }
    const [passwordError, setPasswordError] = useState("");
    const isPasswordValid = (password) => {
        if (!password) {
            setPasswordError("Parola trebuie completata.");
            return false;
        }
        else{
            if (password.length < 8) {
                setPasswordError("Parola trebuie sa contina minim 8 caractere.");
                return false;
            }
            if (!checkPassword(password)) {
                setPasswordError("Parola trebuie sa contina cel putin o majuscula si o cifra.");
                return false;
            }
        }
        return true;
    }

    const updateTeams = () =>
    {
        deleteAllOfUser(ls.get('token'), props.userData.user_id)
        .then(res => {
            addInTeamUserMap(props.userData.user_id);
        }).catch(err => {
            console.log(err);
            showError(props.notificationRef);
        });
    }

    return (
        <>
            <Card title={props.personData.firstname + ' ' + props.personData.lastname}
                  subTitle={getRoleDescription(props.userData.role_id)}
                  className="p-lg-2 p-md-4 p-sm-12 p-ml-3 shortTitle centeredFooter centeredCard p-m-2"
                  header={header} footer={footer}>
            </Card>
            <ConfirmPopup target={document.getElementById('delBtn_' + props.userData.user_id)} visible={visibleDelDlg}
                          onHide={() => setVisibleDelDlg(false)}
                          acceptLabel="Sterge" rejectLabel="Inapoi" className="confirm-popup-del-header"
                          acceptClassName="p-button-danger"
                          message={"Esti sigur ca vrei sa stergi utilizatorul " + props.personData.firstname + ' ' + props.personData.lastname + "? Odata sters, toate datele asociate lui vor disparea complet!"}
                          icon="pi pi-exclamation-triangle" accept={() => execDeleteUser()}
                          reject={() => setVisibleDelDlg(false)}/>
            <Dialog header="Vizualizare/Modificare Utilizator" visible={visibleViewDlg} modal closable={true}
                    className="p-fluid"
                    draggable={true}
                    onHide={() => setVisibleViewDlg(false)}>
                    <div className="p-d-flex p-jc-center">
                <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <Accordion activeIndex={0} style={{width: "50vw"}}>
                    <AccordionTab
                        header={
                        <FormAccordionTabHeader
                            icon="pi pi-user"
                            label="Utilizator"
                            isFormValid={isFormValid("user", formik.touched, formik.errors)}
                        />
                        }
                    >
                        <AddImage 
                            value={profilePic}
                            setImage={setProfilePic}
                            label="Adaugati poza de profil"
                        />
                        <FormInputText
                        name="username"
                        label="Nume utilizator*"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("username", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("username", formik.touched, formik.errors)}
                        disabled={true}
                        />
                        <FormDropdown
                        name="active_id"
                        label="Tip Cont*"
                        value={formik.values.active_id}
                        onChange={formik.handleChange}
                        options={props.allActiveStatus}
                        optionLabel="active_desc"
                        placeholder="Selecteaza tipul"
                        errors={getFormErrorMessage("active_id", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("active_id", formik.touched, formik.errors)}
                        />
                        <FormDropdown
                        name="roleId"
                        label="Rol"
                        value={formik.values.roleId}
                        onChange={formik.handleChange}
                        options={props.allRoles}
                        optionLabel="role_desc"
                        placeholder="Selecteaza rolul"
                        errors={getFormErrorMessage("roleId", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("roleId", formik.touched, formik.errors)}
                        disabled = {true}
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={<FormAccordionTabHeader
                        icon="pi pi-phone"
                        label="Contact"
                        isFormValid={isFormValid("contact", formik.touched, formik.errors)}
                        />}
                    >
                        <FormInputText
                        name="firstname"
                        label="Prenume*"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("firstname", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("firstname", formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="lastname"
                        label="Nume*"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("lastname", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("lastname", formik.touched, formik.errors)}
                        />
                        <div className="p-field">
                        <span className="p-input-icon-right">
                            <label
                            htmlFor="email"
                            className={classNames({
                                "p-error": isFormFieldValid("email", formik.touched, formik.errors),
                            })}
                            >
                            Email*
                            </label>
                            <div className="p-inputgroup">
                            <InputText
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className={classNames({
                                "p-invalid": isFormFieldValid("email", formik.touched, formik.errors),
                                })}
                            />
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            </div>
                        </span>
                        {getFormErrorMessage("email", formik.touched, formik.errors)}
                        </div>
                        <FormInputText
                        name="phone"
                        label="Telefon*"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("phone", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("phone", formik.touched, formik.errors)}
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={<FormAccordionTabHeader
                        icon="pi pi-user-plus"
                        label="Date personale"
                        isFormValid={isFormValid("personal", formik.touched, formik.errors)}/>}
                    >
                        <FormInputText
                        name="pic_cnp"
                        label="CNP*"
                        value={formik.values.pic_cnp}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("pic_cnp", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("pic_cnp", formik.touched, formik.errors)}
                        disabled={true}
                        />
                        <FormDropdown
                        name="nationality"
                        label="Nationalitate"
                        value={formik.values.nationality}
                        onChange={formik.handleChange}
                        options={Nationalities}
                        optionLabel="nationality_desc"
                        errors={getFormErrorMessage("nationality", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("nationality", formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="full_address"
                        label="Adresa"
                        value={formik.values.full_address}
                        onChange={formik.handleChange}
                        />
                        <DropdownCalendar 
                            name="birthdate"
                            value={birthdate === null ? "" : new Date(birthdate)}
                            onChange={(e) => setBirthdate(e.value)}
                            label="Data nasterii"
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={ <FormAccordionTabHeader
                        icon="pi pi-briefcase"
                        label="Date profesionale"/>}
                        disabled = {formik.values.roleId.role_id === 5}
                    >
                        <FormMultiSelect
                        name="teams"
                        label="Echipe"
                        value={selectedTeams}
                        options={props.allTeams}
                        onChange={(e) => setSelectedTeams(e.value)}
                        optionLabel="team_name"
                        placeholder="Selectati o echipa"
                        display="chip"
                        errors={getFormErrorMessage("teams", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("teams",formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="team_history"
                        label="Echipe anterioare"
                        value={formik.values.team_history}
                        onChange={formik.handleChange}
                        />
                         <FormInputText
                        name="licence"
                        label="Licenta"
                        value={formik.values.licence}
                        onChange={formik.handleChange}
                        />
                    </AccordionTab>
                    </Accordion>
                    <Button
                        type="submit"
                        label="Modifica"
                        icon="pi pi-save"
                        className="p-mt-2"
                    />
                </form>
                </div>
            </div>
            </Dialog>
            <Dialog header="Schimba parola" visible={showNewPwDlg} modal closable={true}
                    className="p-fluid"
                    draggable={true}
                    onHide={() => setShowNewPwDlg(false)}>
                <div className="p-d-flex p-jc-center">
                    <div className="card">
                        <Accordion activeIndex={0} style={{width: "50vw", height: "30vw"}}>
                            <AccordionTab header={<React.Fragment><i className="pi pi-user-edit"></i><span> Utilizator</span></React.Fragment>}>
                            <div className="p-field">
                                <span>
                                    <label htmlFor="password">
                                        Parola*
                                    </label>
                                    <Password
                                        id="password"
                                        name="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        toggleMask
                                        header={<FormPasswordHeader/>}
                                        footer={<FormPasswordFooter/>}
                                    />
                                </span>
                            </div>
                            </AccordionTab>
                        </Accordion>
                        <Button 
                            label="Modifica"
                            style={{ width: "50vw" }}
                            icon="pi pi-save"
                            className="p-mt-2"
                            onClick={() => {
                                execChangeUserPw();
                                setShowNewPwDlg(false);
                            }}/>
                    </div>
                </div>
            </Dialog>
        </>
    )
}