import {getDefaultHeader} from '../constants/RESTApiData';
import axiosInstance from "../helpers/axios";

export const getPersonsByUserId = async (token, users_ids) => {
	try {
		const resp = await axiosInstance.post('/person/users_ids',
			{
				vector: users_ids
			},
			{
				headers: getDefaultHeader(token)
			}
		);
		
		if (resp.status === 200 && resp.data)
			return resp.data;
	}
	catch(error) {
		console.log(error);
	}
	return [];
}

export const getAllPersonsWithUsersIds = (firstArray, secondArray) => {
    var innerJoin = [];
	if(firstArray && firstArray.length > 0 && secondArray && secondArray.length > 0){
		firstArray.forEach((person) =>{
			var user = secondArray.find((user) => user.user_id === person.user_id);
			if(user){
				person.username = user.username;
				person.active_id = user.active_id;
				person.role_id = user.role_id;
			}
			innerJoin.push(person);
		});
	}
    return innerJoin;
}