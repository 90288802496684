import React from "react";
import FormInputText from "../Form/FormInputText";
import FormDropdown from "../Form/FormDropdown";
import FormInputNumber from "../Form/FormInputNumber";
import DropdownCalendar from "../Form/FormCalendar";
import { Nationalities } from "../Form/FormNationalities";
import "../../styles/SearchBar.css";
import {
  getFormErrorMessage,
  isFormFieldValid,
} from "./FormValidationNewPlayer";

const PlayerPersonalFields = (props) => {
  return (
    <div>
      <FormInputText
        name="pic_cnp"
        label="CNP*"
        value={props.values.pic_cnp}
        onChange={props.onChange}
        errors={getFormErrorMessage("pic_cnp", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "pic_cnp",
          props.touched,
          props.errors
        )}
        disabled = {!props.addNew}
      />
      <FormDropdown
        name="nationality"
        label="Nationalitate"
        value={props.values.nationality}
        onChange={props.onChange}
        options={Nationalities}
        optionLabel="nationality_desc"
        placeholder="Selectati o nationalitate"
        errors={getFormErrorMessage(
          "nationality",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "nationality",
          props.touched,
          props.errors
        )}
      />
      <FormInputText
        name="full_address"
        label="Adresa"
        value={props.values.full_address}
        onChange={props.onChange}
        errors={getFormErrorMessage(
          "full_address",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "full_address",
          props.touched,
          props.errors
        )}
      />
      <FormInputNumber
        name="weight"
        label="Greutate (kg)*"
        value={props.playerWeight}
        onChange={props.setPlayerWeight}
        errors={getFormErrorMessage("weight", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "weight",
          props.touched,
          props.errors
        )}
      />
      <FormInputNumber
        name="height"
        label="Inaltime (cm)*"
        value={props.playerHeight}
        onChange={props.setPlayerHeight}
        errors={getFormErrorMessage("height", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "height",
          props.touched,
          props.errors
        )}
      />
      <DropdownCalendar
        name="birthdate"
        value={props.playerBirthdate}
        onChange={props.setPlayerBirthdate}
        label="Data nasterii"
        errors={getFormErrorMessage("birthdate", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "birthdate",
          props.touched,
          props.errors
        )}
      />
    </div>
  );
};
export default PlayerPersonalFields;
