import React from "react";
import { InputText } from "primereact/inputtext";
import "../../styles/SearchBar.css";

const SearchBar = (props) => {
  return (
    <div className="Search">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={props.onInput}
          placeholder={props.placeholder}
        />
      </span>
    </div>
  );
};
export default SearchBar;
