export const checkPassword = (password) => {
    let upper_count = 0;
    let lower_count = 0;
    let number_count = 0;
    let symbol_count = 0
    for(let i = 0; i < password.length; i++) {
      let c = password.charAt(i);
  
      if( 'A' <= c && c <= 'Z') {
        upper_count +=1;
      }else if( 'a' <= c && c <= 'z') {
        lower_count +=1;
      }else if( '0' <= c && c <= '9') {
        number_count +=1;
      } else {
        symbol_count += 1;
      }
    }
    if(upper_count > 0 && number_count >0){
      return true;
    }
    else{
      return false;
    }
  }