import React from "react";
import { Button } from "primereact/button";

export const deletePlayerDialogFooter = (hideDeletePlayerDialog, deletePlayer) => {
  return (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletePlayerDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deletePlayer}
      />
    </React.Fragment>
  );
};
