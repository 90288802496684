import React from "react";

const FormAccordionTabHeader = (props) => {
    return (
      <div>
        <span>
          <p
            style={{
              color: props.isFormValid ? "red" : "black",
            }}
          >
            <i className={props.isFormValid ? "pi pi-times-circle" : props.icon}></i>{" "}
            {props.label}
          </p>
        </span>
      </div>
    );
  };

  export default FormAccordionTabHeader;