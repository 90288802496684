import React from "react";
import { Divider } from "primereact/divider";

const FormPasswordFooter = () => {
  return (
    <div>
      <Divider />
      <p className="p-mt-2">Sugestii</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
        <li>Cel putin o majuscula</li>
        <li>Cel putin o cifra</li>
        <li>Minim 8 caractere</li>
      </ul>
    </div>
  );
};

export default FormPasswordFooter;
