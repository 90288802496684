import {getDefaultHeader} from '../constants/RESTApiData';
import axiosInstance from "../helpers/axios";

export const getAllPersons = async (token) => {
     return await axiosInstance.get('/person/getAll', {
         headers: getDefaultHeader(token)
     })
     .then(response => {
        return response.data;
      }); 
};