import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { deletePlayerDialogFooter } from "../components/PlayerPage/Footers";
import PlayerSubtables from "../components/PlayerPage/PlayerSubtables";
import PlayerActions from "../components/PlayerPage/PlayerActions";
import PlayersTable from "../components/PlayerPage/PlayersTable";
import PlayerAddEditDialog from "./PlayerPage/PlayerAddEditDialog";
import PlayerDeleteDialog from "./PlayerPage/PlayerDeleteDialog";
import {
  emptyPlayer,
  fields,
  empty,
} from "../components/PlayerPage/HardcodedData";
import {isNullOrEmpty} from "../services/array.service";
import { getTeamsOfUserIdLink } from "../services/event.service";
import { createUserTeamMap } from "../services/administration.service";
import { deleteAllOfUser } from "../services/administration.service";
import { areFieldsValid } from "../components/PlayerPage/FormValidationNewPlayer";
import { SetPlayerErrors } from "./PlayerPage/SetPlayerErrors";
import { getAllPlayPost } from "../services/getAllPlayPost.service";
import { getAllPrefferedFoot } from "../services/getAllPrefferedFoot.service";
import { getAllPlayers } from "../services/getAllPlayers.service";
import { getAllTeams } from "../services/getAllTeams.service";
import { getAllUsers } from "../services/administration.service";
import { getAllPersons } from "../services/getAllPersons.service";
import { deleteUser } from "../services/administration.service";
import { getAllPersonsWithUsersIds } from "../services/getPersonsByUserId.service";
import ls from 'local-storage';
import '../styles/Table.css';
import '../styles/Players.css';
import { updatePlayer } from "../services/updatePlayer.service";
import { createPlayer } from "../services/createPlayer.service";
import moment from "moment";
import PlayerOperations from "./PlayerPage/PlayerOperations";
import { getNationality } from "./Form/FormGetNationality";

export default function(props){
  const [allTeams, setAllTeams] = useState([]);
  const [allPlayPosts, setAllPlayPosts] = useState([]);
  const [allPrefferedFoot, setAllPrefferedFoot] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [allUsers, setAllUsers] = useState(null);
  const [allPersons, setAllPersons] = useState(null);
  const [player, setPlayer] = useState(emptyPlayer);
  const [addNew, setAddNew] = useState(false);
  const [loggedUserTeams, setLoggedUserTeams] = useState([]);
  const [playerWeight, setPlayerWeight] = useState(0);
  const [playerHeight, setPlayerHeight] = useState(0);
  const [playerShirtNumber, setPlayerShirtNumber] = useState(0);
  const [playerBirthdate, setPlayerBirthdate] = useState(null);
  const [playerStartDate, setPlayerStartDate] = useState(null);
  const [playerEndDate, setPlayerEndDate] = useState(null);
  const [playerProfilePic, setPlayerProfilePic] = useState(
    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [playerDialog, setPlayerDialog] = useState(false);
  const [deletePlayerDialog, setDeletePlayerDialog] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [newPlayerTeams, setNewPlayerTeams] = useState([]);
  const [teamFilter, setTeamFilter] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const isInitialMount = useRef(true);

  React.useEffect(() => {
    if(isInitialMount.current){
      isInitialMount.current = false;
      var userList = [];
      getAllUsers(ls.get("token"))
        .then((data) => {
          data.forEach(user => {
              userList.push(user);
          });
          setAllUsers(userList);
        })
        .catch((err) => {
          console.log(err);
        });
  
      let teamsOption = [];
  
      getAllTeams(ls.get("token"))
        .then((result) => {
          if (result !== "" && result !== null && result !== undefined) {
            result.forEach((item) => {
              let newOption = {
                label: item["team_name"],
                value: item["team_id"],
              };
              teamsOption.push(newOption);
            });
            setAllTeams(teamsOption);
            setTeamFilter(teamsOption[0].value);
          } else {
            if (this.toastRef !== null) {
              this.toastRef.clear();
              this.toastRef.show({
                life: 5000,
                severity: "warn",
                summary: "Atentie !",
                detail: "Nu exista nicio echipa !",
              });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              ls.set("token", "");
              ls.set("username", "");
              ls.set("authSucc", false);
              ls.set("dataLoad", false);
              window.location.reload();
            }
          }
        });
      var players = [];
      getAllPlayers(ls.get("token"))
        .then((result) => {
          if (result !== "" && result !== null && result !== undefined) {
            result.forEach((item) => {
              var player = {
              user_id: item["user_id"],
              person_id: item["person_id"],
              player_id: item["player_id"],
              username: item["username"],
              name: item["firstname"] + " " + item["lastname"],
              firstname: item["firstname"],
              lastname: item["lastname"],
              image: "",
              birthdate: item["birthdate"],
              birthdate_formatted: moment(item["birthdate"]).format("DD-MM-YYYY"),
              age:new Date().getFullYear() - moment(item["birthdate"]).format("YYYY"),
              nationality: item["nationality"],
              shirt_number: item["shirt_number"],
              play_post: {
                  play_post_desc: item["play_post_desc"],
                  play_post_id: item["play_post_id"]
              },
              injured: item["injured"],
              pic_cnp: item["pic_cnp"],
              email: item["email"],
              phone: item["phone"],
              full_address: item["full_address"],
              licence: item["licence"],
              weight: item["weight"],
              height: item["height"],
              preffered_foot: {
                  preffered_foot_desc: item["preffered_foot_desc"],
                  preffered_foot_id: item["preffered_foot_id"]
              },
              team_history: item["team_history"],
              coach_history: item["coach_history"],
              teams: [],
              };
              player.teams.push({
              team_name: item["team_name"],
              team_id: item["team_id"]
              });
              var exists = players.find(p=>p.user_id === item.user_id);
              if(exists) {
                  for(var i = 0;i<players.length;i++){
                      if(players[i].user_id === exists.user_id){
                          players[i].teams.push({
                              team_name: item["team_name"],
                              team_id: item["team_id"]
                              })
                      }
                  }
              }
              else{
                  players.push(player);
              }
            });
            setAllPlayers(players);
          }
          else {
              if (this.toastRef !== null) {
                  this.toastRef.clear();
                  this.toastRef.show({
                  life: 5000,
                  severity: "warn",
                  summary: "Atentie !",
                  detail: "Nu exista niciun jucator!",
                  });
              }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              ls.set("token", "");
              ls.set("username", "");
              ls.set("authSucc", false);
              ls.set("dataLoad", false);
              window.location.reload();
            }
          }
        });
    
        var allPersons = [];
        getAllPersons(ls.get("token"))
        .then((data) => {
          if(data && data.length > 0 ){
            data.forEach(person => {
              allPersons.push(person);
            });
            setAllPersons(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
        
        let listPosts = [];
        getAllPlayPost(ls.get("token"))
          .then((result) => {
            if (result !== null && result !== "" && result !== undefined) {
              result.forEach((item) => {
                listPosts.push({
                  play_post_desc: item["play_post_desc"],
                  play_post_id: item["play_post_id"],
                });
              });
              setAllPlayPosts(listPosts);
            } else {
              if (this.toastRef !== null) {
                this.toastRef.clear();
                this.toastRef.show({
                  life: 5000,
                  severity: "warn",
                  summary: "Atentie !",
                  detail: "Nu exista niciun post de joc !",
                });
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                ls.set("token", "");
                ls.set("username", "");
                ls.set("authSucc", false);
                ls.set("dataLoad", false);
                window.location.reload();
              }
            }
          });
    
        let listFoot = [];
        getAllPrefferedFoot(ls.get("token"))
          .then((result) => {
            if (result !== null && result !== undefined && result !== "") {
              result.forEach((item) => {
                listFoot.push({
                  preffered_foot_desc: item["preffered_foot_desc"],
                  preffered_foot_id: item["preffered_foot_id"],
                });
              });
              setAllPrefferedFoot(listFoot);
            } 
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (error.response.status === 401) {
                ls.set("token", "");
                ls.set("username", "");
                ls.set("authSucc", false);
                ls.set("dataLoad", false);
                window.location.reload();
              }
            }
          });

          const clonedLoggedUserTeamsLinked = [];
          if (ls.get('role_id') <4){
            getTeamsOfUserIdLink(ls.get('token'), ls.get('user_id')).then(res => {
              if (res.data != null && res.data.length !== 0 && res.data[0] !== undefined) {
                  res.data.forEach(team => {
                      clonedLoggedUserTeamsLinked.push({label: team.team_name, value: team.team_id});
                  });
                  setLoggedUserTeams(clonedLoggedUserTeamsLinked);
                  setTeamFilter(clonedLoggedUserTeamsLinked[0].value);
              }
            }).catch(err => {
                console.log(err);
            })
          }
    }
    else{
      var players = [];
      getAllPlayers(ls.get("token"))
        .then((result) => {
          if (result !== "" && result !== null && result !== undefined) {
            result.forEach((item) => {
              var player = {
              user_id: item["user_id"],
              person_id: item["person_id"],
              player_id: item["player_id"],
              username: item["username"],
              name: item["firstname"] + " " + item["lastname"],
              firstname: item["firstname"],
              lastname: item["lastname"],
              image: "",
              birthdate: item["birthdate"],
              birthdate_formatted: moment(item["birthdate"]).format("DD-MM-YYYY"),
              age:new Date().getFullYear() - moment(item["birthdate"]).format("YYYY"),
              nationality: item["nationality"],
              shirt_number: item["shirt_number"],
              play_post: {
                  play_post_desc: item["play_post_desc"],
                  play_post_id: item["play_post_id"]
              },
              injured: item["injured"],
              pic_cnp: item["pic_cnp"],
              email: item["email"],
              phone: item["phone"],
              full_address: item["full_address"],
              licence: item["licence"],
              weight: item["weight"],
              height: item["height"],
              preffered_foot: {
                  preffered_foot_desc: item["preffered_foot_desc"],
                  preffered_foot_id: item["preffered_foot_id"]
              },
              team_history: item["team_history"],
              coach_history: item["coach_history"],
              teams: [],
              };
              player.teams.push({
              team_name: item["team_name"],
              team_id: item["team_id"]
              });
              var exists = players.find(p=>p.user_id === item.user_id);
              if(exists) {
                  for(var i = 0;i<players.length;i++){
                      if(players[i].user_id === exists.user_id){
                          players[i].teams.push({
                              team_name: item["team_name"],
                              team_id: item["team_id"]
                              })
                      }
                  }
              }
              else{
                  players.push(player);
              }
            });
            if(JSON.stringify(players) !== JSON.stringify(allPlayers)){
              setAllPlayers(players);
            }
          }
          else {
              if (this.toastRef !== null) {
                  this.toastRef.clear();
                  this.toastRef.show({
                  life: 5000,
                  severity: "warn",
                  summary: "Atentie !",
                  detail: "Nu exista niciun jucator!",
                  });
              }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              ls.set("token", "");
              ls.set("username", "");
              ls.set("authSucc", false);
              ls.set("dataLoad", false);
              window.location.reload();
            }
          }
        });
    }
  },[allPlayers]);

  const formik = useFormik({
    initialValues: player,
    validate: (data) => {
      data.height = playerHeight;
      data.weight = playerWeight;
      data.shirt_number = playerShirtNumber;
      data.image = playerProfilePic;
      data.birthdate = playerBirthdate;
      data.start_date = playerStartDate;
      data.end_date = playerEndDate;
      data.username = (Math.random() + 1).toString(36).substring(7);
      data.password = (Math.random() + 1).toString(36).substring(7);
      data.teams = selectedTeams;
      const team_list = [];
      if(selectedTeams !== null){
        selectedTeams.forEach(team => {
          team_list.push(allTeams.find((t)=> t.value === team));
        });
      }
      setNewPlayerTeams(team_list);

      if(addNew)
      {
        data.teams = "";
        if(selectedTeams !== null)
        {
          selectedTeams.forEach((team) => (data.teams = data.teams + team + ","));
          data.teams = data.teams.slice(0, -1);
        }
      }
      return areFieldsValid(data, addNew, getAllPersonsWithUsersIds(allPersons, allUsers));
    },
    onSubmit: (data) => {
      savePlayer(data);
      formik.resetForm();
    }
  });

  const deletePlayer = () => {
    deleteUser(ls.get('token'), player.user_id)
    .then(res => {
        if (res === null || res === undefined || res === ''){
          toast.current.show({
            severity: "error",
            summary: "Eroare",
            detail: "Jucator nu a fost sters.",
            life: 3000,
          });
        }
        else {
          let _players = allPlayers.filter((val) => val.user_id !== player.user_id);
          setAllPlayers(_players);
          setPlayer(emptyPlayer);
          toast.current.show({
            severity: "success",
            summary: "Succes",
            detail: "Jucator sters.",
            life: 3000,
          });
        }
    }).catch(err => {
    console.log(err);
  })
    setDeletePlayerDialog(false);
  };

  const openNew = () => {
    setAddNew(true);
    formik.resetForm();
    setPlayerWeight(0);
    setPlayerHeight(0);
    setPlayerShirtNumber(0);
    setPlayerBirthdate(null);
    setPlayerStartDate(null);
    setPlayerEndDate(null);
    setPlayerProfilePic(
      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
    );
    setSelectedTeams(null);

    setPlayer(empty);
    fields.forEach((field) => (formik.values[field] = empty[field]));

    formik.values.weight = playerWeight;
    formik.values.height = playerHeight;
    formik.values.shirt_number = playerShirtNumber;
    formik.values.image = playerProfilePic;
    formik.values.birthdate = playerBirthdate;
    formik.values.start_date = playerStartDate;
    formik.values.end_date = playerEndDate;
    formik.values.teams = selectedTeams;

    setPlayerDialog(true);
  };

  const hideDeletePlayerDialog = () => {
    setDeletePlayerDialog(false);
  };

  const hideDialog = () => {
    setAddNew(false);
    setPlayerDialog(false);
  };

  const findIndexByUserId = (user_id) => {
    let index = -1;
    for (let i = 0; i < allPlayers.length; i++) {
        if (allPlayers[i].user_id === user_id) {
            index = i;
            break;
        }
    }

    return index;
  }

  const getTeamUserMapObj = (user_id, team_id) => {
    return {
        team_id: team_id,
        user_id: user_id
    }
  }

  const addInTeamUserMap = (user_id) => {
    if(selectedTeams !== null && selectedTeams !== undefined){
        selectedTeams.forEach(team=> {
            createUserTeamMap(ls.get('token'), getTeamUserMapObj(user_id, team))
                .then(res => {
                    //showSuccess(notificationRef);
                }).catch(err => {
                  toast.current.show({
                    severity: "error",
                    summary: "Eroare",
                    detail: "Jucatorul nu a fost modificat.",
                    life: 3000,
                  });
                    console.log(err);
                })
        });
    }
}

  const updateTeams = (playerId) =>
  {
      deleteAllOfUser(ls.get('token'), playerId)
      .then(res => {
          addInTeamUserMap(playerId);
      }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: "Eroare",
            detail: "Jucatorul nu a fost modificat.",
            life: 3000,
          });
      });
  }

  const savePlayer = (data) => {
    if (data.username.trim()) {
      let _player = { ...data };
      let _players = [ ...allPlayers];
      _player.birthdate_formatted = moment(_player["birthdate"]).format("DD-MM-YYYY");
      _player.age = new Date().getFullYear() - moment(_player["birthdate"]).format("YYYY");
      _player.name = _player.firstname + " " + _player.lastname;
      _player.nationality = _player.nationality.nationality_desc;
      if (data.user_id !== 0) {
        updatePlayer(ls.get('token'), 
         _player.user_id, 
         _player.firstname, 
         _player.lastname, 
         _player.email, 
         _player.phone, 
         _player.pic_cnp, 
         _player.nationality, 
         _player.birthdate,
         _player.full_address, 
         _player.team_history, 
         _player.coach_history, 
         _player.licence, 
         _player.play_post.play_post_id, 
         _player.preffered_foot.preffered_foot_id, 
         _player.shirt_number, 
         _player.weight, 
         _player.height, 
         _player.injured)
        .then((res) => {
            if (res === null || res === undefined || res === ''){
              const index = findIndexByUserId(data.user_id);
              _players[index]=_player;
              setAllPlayers(_players);
              updateTeams(_player.user_id);
              toast.current.show({
                severity: "success",
                summary: "Succes",
                detail: "Jucator Modificat",
                life: 3000,
              });
            }
            else {
              toast.current.show({
                severity: "error",
                summary: "Eroare",
                detail: "Jucatorul nu a fost modificat.",
                life: 3000,
              });
            }
        }).catch(err => {
            console.log(err);
        });
      } else {
        const objectToAdd = {
          username: _player.username, 
          password: _player.password, 
          firstname: _player.firstname, 
          lastname: _player.lastname, 
          email: _player.email, 
          phone: _player.phone, 
          pic_cnp: _player.pic_cnp, 
          nationality: _player.nationality, 
          birthdate: _player.birthdate == null ? null : _player.birthdate.toLocaleDateString("en-GB").replaceAll("/","-").split("-").reverse().join("-"),
          full_address: _player.full_address, 
          team_history: _player.team_history, 
          coach_history: _player.coach_history, 
          licence: _player.licence, 
          play_post_id:_player.play_post.play_post_id, 
          preffered_foot_id: _player.preffered_foot.preffered_foot_id, 
          shirt_number: _player.shirt_number, 
          weight: _player.weight, 
          height: _player.height, 
          injured: _player.injured, 
          team_id: _player.teams,
          join_date: _player.start_date.toLocaleDateString("en-GB").replaceAll("/","-").split("-").reverse().join("-"), 
          expiry_date: _player.endDate == null ? null : _player.end_date.toLocaleDateString("en-GB").replaceAll("/","-").split("-").reverse().join("-"),
        }
        createPlayer(ls.get('token'), objectToAdd)
        .then((res) => {
          if (res === null || res === undefined || res === ''){
            toast.current.show({
              severity: "error",
              summary: "Eroare",
              detail: "Jucatorul nu a fost creat. ",
              life: 3000,
            });
          }
          else {
            _player.teams=newPlayerTeams;
            _player.user_id = res.data;
            setAllPlayers(allPlayers => [...allPlayers, _player])
            toast.current.show({
              severity: "success",
              summary: "Succes",
              detail: "Jucator creat",
              life: 3000,
            });
          }
        }).catch(err => {
            console.log(err);
        });
      }
      setPlayerDialog(false);
      setAddNew(false);
      setPlayer(emptyPlayer);
    }
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.image}
        //src={playerProfilePic}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };

  const editPlayer = (player) => {

    SetPlayerErrors(formik.errors, "");
    fields.forEach((field) => (formik.values[field] = player[field]));
    setPlayerWeight(formik.values.weight);
    setPlayerHeight(formik.values.height);
    setPlayerShirtNumber(formik.values.shirt_number);
    setPlayerBirthdate(formik.values.birthdate);
    setPlayerProfilePic(
      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
    );

    const teams_ids = [];
    formik.values.teams.forEach(team => {
      teams_ids.push(team.team_id);
    });
    setSelectedTeams(teams_ids);

    formik.values.nationality = getNationality(player.nationality);

    if (formik.values.image.trim() !== "") {
      setPlayerProfilePic(formik.values.image);
    }
    else{
      formik.values.image = playerProfilePic;
    }

    setPlayer({ ...player });
    setPlayerDialog(true);
  };

  const confirmDeletePlayer = (player) => {
    setPlayer(player);
    setDeletePlayerDialog(true);
  };

  const actionsBody = (rowData) => {
    return (
      <PlayerActions
        onEdit={() => editPlayer(rowData)}
        onDelete={() => confirmDeletePlayer(rowData)}
      />
    );
  };

  const rowExpansionTemplate = (rowData) => {
    const selectedPlayer = [];
    selectedPlayer.push(rowData);
    return <PlayerSubtables className="p-datatable-responsive-demo" value={selectedPlayer} />;
  };

  return (
    <div id="table" className="datatable-responsive-demo">
      <br></br>
      <Toast ref={toast} />
      <PlayerOperations 
        onInputSearch={(e) => setGlobalFilter(e.target.value)}
        teamFilterValue={teamFilter}
        teamFilterOnChange={(e) => setTeamFilter(e.value)}
        teamFilterOptions={ls.get('role_id') === 5 ||  ls.get('role_id') === 4 ? allTeams : loggedUserTeams}
        addNewPlayerOnClick={openNew}/>
      <PlayersTable 
        tableValue={allPlayers.filter(player=> player.teams.some(playerTeam => playerTeam.team_id === teamFilter))}
        tableExpandedRows={expandedRow}
        tableOnRowToggle={(e) => setExpandedRow(e.data)}
        tableRowExpansionTemplate={rowExpansionTemplate}
        tableGlobalFilter={globalFilter}
        imageBodyTemplate={imageBodyTemplate}
        tableActionsBody={actionsBody}
      />
      <PlayerAddEditDialog 
        visible={playerDialog}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        addNew={addNew}
        onHide={hideDialog}
        handleSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
        profilePicValue={playerProfilePic}
        setPlayerProfilePic={setPlayerProfilePic}
        playerWeight={playerWeight}
        setPlayerWeight={(e) => setPlayerWeight(e.value)}
        playerHeight={playerHeight}
        setPlayerHeight={(e) => setPlayerHeight(e.value)}
        playerBirthdate={playerBirthdate == null ? null : new Date(playerBirthdate)}
        setPlayerBirthdate={(e) => setPlayerBirthdate(e.value)}
        shirtNumber={playerShirtNumber}
        setShirtNumber={(e) => setPlayerShirtNumber(e.value)}
        selectedTeams={selectedTeams}
        allTeams={ls.get('role_id') === 5 ||  ls.get('role_id') === 4 ? allTeams : loggedUserTeams}
        setSelectedTeams={(e) => setSelectedTeams(e.value)}
        allPlayPosts={allPlayPosts}
        allPrefferedFoot={allPrefferedFoot}
        startDate={playerStartDate}
        setStartDate={(e) => setPlayerStartDate(e.value)}
        endDate={playerEndDate}
        setEndDate={(e) => setPlayerEndDate(e.value)}
      />
      <PlayerDeleteDialog 
        visible={deletePlayerDialog}
        footer={deletePlayerDialogFooter(hideDeletePlayerDialog, deletePlayer)}
        onHide={hideDeletePlayerDialog}
        player={player}
      />
    </div>
  );
};