import React from "react";
import { Button } from "primereact/button";
import '../../styles/Players.css';

const AddNewPlayerButton = (props) => {
  return (
    <div className="add-new">
      <Button
        icon="pi pi-plus"
        label="Adaugare"
        onClick={props.onClick}
      />
    </div>
  );
};

export default AddNewPlayerButton;
