import React from "react";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import classNames from "classnames";

const DropdownCalendar = (props) => {
  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = month === 0 ? 11 : month - 1;
  let prevYear = prevMonth === 11 ? year - 1 : year;
  let nextMonth = month === 11 ? 0 : month + 1;
  let nextYear = nextMonth === 0 ? year + 1 : year;

  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);

  let maxDate = new Date();
  maxDate.setMonth(nextMonth);
  maxDate.setFullYear(nextYear);

  addLocale("ro", {
    firstDayOfWeek: 0,
    dayNames: [
      "Luni",
      "Marti",
      "Miercuri",
      "Joi",
      "Vineri",
      "Sambata",
      "Duminica",
    ],
    dayNamesShort: ["Lun", "Mar", "Mie", "Joi", "Vin", "Sam", "Dum"],
    dayNamesMin: ["L", "M", "M ", "J", "V", "S", "D"],
    monthNames: [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iulie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie",
    ],
    monthNamesShort: [
      "Ian",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Iun",
      "Iul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Astazi",
    clear: "Goleste",
  });
  return (
    <div>
      <div className="p-field">
        <span>
          <label
          htmlFor={props.name}
          className={classNames({
            "p-error": props.formFieldValid,
          })}
        >
          {props.label}
        </label>
          <Calendar
            dateFormat="dd/mm/yy"
            htmlFor={props.name}
            value={props.value}
            onChange={props.onChange}
            monthNavigator
            yearNavigator
            yearRange={"1930:" + new Date().getFullYear()}
            locale="ro"
            showIcon
            className={classNames({
              "p-invalid": props.formFieldValid,
            })}
          />
          {props.errors}
        </span>
      </div>
    </div>
  );
};
export default DropdownCalendar;
