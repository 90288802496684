import React from "react";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";

const FormInputNumber = (props) => {
  return (
    <div className="p-field">
      <span>
        <label
          htmlFor={props.name}
          className={classNames({
            "p-error": props.formFieldValid,
          })}
        >
          {props.label}
        </label>
        <InputNumber
          id={props.name}
          name={props.name}
          value={props.value}
          onValueChange={props.onChange}
          suffix= {props.suffix}
          className={classNames({
            "p-invalid": props.formFieldValid,
          })}
        />
      </span>
      {props.errors}
    </div>
  );
};

export default FormInputNumber;
