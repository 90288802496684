import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ContactSubtable = (props) => {
  return (
    <div>
      <DataTable className={props.className} value={props.value} style={{marginLeft:"4.5%"}} responsiveLayout="stack">
        <Column className="column" field="pic_cnp" header="CNP"></Column>
        <Column className="column" field="email" header="Email"></Column>
        <Column className="column" field="phone" header="Numar Telefon"></Column>
        <Column className="column" field="full_address" header="Domiciliu"></Column>
        <Column className="column" field="licence" header="Licenta"></Column>
      </DataTable>
      <br />
    </div>
  );
};
export default ContactSubtable;
