export const Nationalities =[{nationality_id: 1, nationality_desc: 'Afgan' }, 
{nationality_id: 1, nationality_desc: 'Afgană' }, 
{nationality_id: 2, nationality_desc: 'Afrikander' }, 
{nationality_id: 3, nationality_desc: 'Albanez' }, 
{nationality_id: 4, nationality_desc: 'Albaneză' }, 
{nationality_id: 5, nationality_desc: 'Algerian' }, 
{nationality_id: 6, nationality_desc: 'American' }, 
{nationality_id: 7, nationality_desc: 'Americană' }, 
{nationality_id: 8, nationality_desc: 'Arabă' }, 
{nationality_id: 9, nationality_desc: 'Armeană' }, 
{nationality_id: 10, nationality_desc: 'Aromân' }, 
{nationality_id: 11, nationality_desc: 'Aromână' }, 
{nationality_id: 12, nationality_desc: 'Austriac' }, 
{nationality_id: 13, nationality_desc: 'Azer' }, 
{nationality_id: 14, nationality_desc: 'Azeră' }, 
{nationality_id: 15, nationality_desc: 'Bască' }, 
{nationality_id: 16, nationality_desc: 'Belgiană' }, 
{nationality_id: 17, nationality_desc: 'Brazilian' }, 
{nationality_id: 18, nationality_desc: 'Britanic' }, 
{nationality_id: 19, nationality_desc: 'Bulgar' }, 
{nationality_id: 20, nationality_desc: 'Bulgară' }, 
{nationality_id: 21, nationality_desc: 'Calabreză' }, 
{nationality_id: 22, nationality_desc: 'Canadian' }, 
{nationality_id: 23, nationality_desc: 'Catalan' }, 
{nationality_id: 24, nationality_desc: 'Catalană' }, 
{nationality_id: 25, nationality_desc: 'Ceh' }, 
{nationality_id: 26, nationality_desc: 'Cehă' }, 
{nationality_id: 27, nationality_desc: 'Cehoaică' }, 
{nationality_id: 28, nationality_desc: 'Chinez' }, 
{nationality_id: 29, nationality_desc: 'Chineză' }, 
{nationality_id: 30, nationality_desc: 'Chinezoaică' }, 
{nationality_id: 31, nationality_desc: 'Coreean' }, 
{nationality_id: 32, nationality_desc: 'Coreeană' }, 
{nationality_id: 33, nationality_desc: 'Corsicană' }, 
{nationality_id: 34, nationality_desc: 'Croată' }, 
{nationality_id: 35, nationality_desc: 'Daneză' }, 
{nationality_id: 36, nationality_desc: 'Elvețian' }, 
{nationality_id: 37, nationality_desc: 'Englez' }, 
{nationality_id: 38, nationality_desc: 'Engleză' }, 
{nationality_id: 39, nationality_desc: 'Estoniană' }, 
{nationality_id: 40, nationality_desc: 'Finlandeză' }, 
{nationality_id: 41, nationality_desc: 'Francez' }, 
{nationality_id: 42, nationality_desc: 'Franceză' }, 
{nationality_id: 43, nationality_desc: 'Friulană' }, 
{nationality_id: 44, nationality_desc: 'Germană' }, 
{nationality_id: 45, nationality_desc: 'Ghaneză' }, 
{nationality_id: 46, nationality_desc: 'Greacă' }, 
{nationality_id: 47, nationality_desc: 'Indian' }, 
{nationality_id: 48, nationality_desc: 'Irlandeză' }, 
{nationality_id: 49, nationality_desc: 'Islandeză' }, 
{nationality_id: 50, nationality_desc: 'Italiană' }, 
{nationality_id: 51, nationality_desc: 'Japonez' }, 
{nationality_id: 52, nationality_desc: 'Japoneză' }, 
{nationality_id: 53, nationality_desc: 'Latină' }, 
{nationality_id: 54, nationality_desc: 'Letonă' }, 
{nationality_id: 55, nationality_desc: 'Lituaniană' }, 
{nationality_id: 56, nationality_desc: 'Maghiară' }, 
{nationality_id: 57, nationality_desc: 'Malgașă' }, 
{nationality_id: 58, nationality_desc: 'Malteză' }, 
{nationality_id: 59, nationality_desc: 'Moldovean' }, 
{nationality_id: 60, nationality_desc: 'Neerlandeză' }, 
{nationality_id: 61, nationality_desc: 'Nipon' }, 
{nationality_id: 62, nationality_desc: 'Niponă' }, 
{nationality_id: 63, nationality_desc: 'Norvegiană' }, 
{nationality_id: 64, nationality_desc: 'Occitană' }, 
{nationality_id: 65, nationality_desc: 'Olandeză' }, 
{nationality_id: 66, nationality_desc: 'Pakistanez' }, 
{nationality_id: 67, nationality_desc: 'Poloneză' }, 
{nationality_id: 68, nationality_desc: 'Portugheză' }, 
{nationality_id: 69, nationality_desc: 'Român' }, 
{nationality_id: 70, nationality_desc: 'Română' }, 
{nationality_id: 71, nationality_desc: 'Româncă' }, 
{nationality_id: 72, nationality_desc: 'Rusă' }, 
{nationality_id: 73, nationality_desc: 'Sârbă' }, 
{nationality_id: 74, nationality_desc: 'Scoțiană' }, 
{nationality_id: 75, nationality_desc: 'Siciliană' }, 
{nationality_id: 76, nationality_desc: 'Slavă' }, 
{nationality_id: 77, nationality_desc: 'Slovenă' }, 
{nationality_id: 78, nationality_desc: 'Spaniol' }, 
{nationality_id: 79, nationality_desc: 'Spaniolă' }, 
{nationality_id: 80, nationality_desc: 'Sudaneză' }, 
{nationality_id: 81, nationality_desc: 'Suedeză' }, 
{nationality_id: 82, nationality_desc: 'Tadjică' }, 
{nationality_id: 83, nationality_desc: 'Turcă' }, 
{nationality_id: 84, nationality_desc: 'Ungur' }, 
{nationality_id: 85, nationality_desc: 'Vietnameză' }, 
{nationality_id: 86, nationality_desc: 'Yuki' }]