import React from "react";
import EditPlayer from "./EditPlayer";
import DeletePlayer from "./DeletePlayer";

const PlayerActions = (props) => {
  return (
    <React.Fragment>
      <EditPlayer onClick={props.onEdit}/>
      <DeletePlayer onClick={props.onDelete}/>
    </React.Fragment>
  );
};

export default PlayerActions;
