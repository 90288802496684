import React from "react";
import FormInputText from "../Form/FormInputText";
import { InputText } from "primereact/inputtext";
import "../../styles/SearchBar.css";
import {
  getFormErrorMessage,
  isFormFieldValid,
} from "./FormValidationNewPlayer";
import classNames from "classnames";

const PlayerContactFields = (props) => {
  return (
    <div>
      <FormInputText
        name="firstname"
        label="Prenume*"
        value={props.values.firstname}
        onChange={props.onChange}
        errors={getFormErrorMessage("firstname", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "firstname",
          props.touched,
          props.errors
        )}
      />
      <FormInputText
        name="lastname"
        label="Nume*"
        value={props.values.lastname}
        onChange={props.onChange}
        errors={getFormErrorMessage("lastname", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "lastname",
          props.touched,
          props.errors
        )}
      />
      <div className="p-field">
        <span className="p-input-icon-right">
          <label
            htmlFor="email"
            className={classNames({
              "p-error": isFormFieldValid(
                "email",
                props.touched,
                props.errors
              ),
            })}
          >
            Email*
          </label>
          <div className="p-inputgroup">
            <InputText
              id="email"
              name="email"
              value={props.values.email}
              onChange={props.onChange}
              className={classNames({
                "p-invalid": isFormFieldValid(
                  "email",
                  props.touched,
                  props.errors
                ),
              })}
            />
            <span className="p-inputgroup-addon">
              <i className="pi pi-envelope"></i>
            </span>
          </div>
        </span>
        {getFormErrorMessage("email", props.touched, props.errors)}
      </div>
      <FormInputText
        name="phone"
        label="Telefon*"
        value={props.values.phone}
        onChange={props.onChange}
        errors={getFormErrorMessage("phone", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "phone",
          props.touched,
          props.errors
        )}
      />
    </div>
  );
};
export default PlayerContactFields;
