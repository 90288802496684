import React from "react";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

const FormInputText = (props) => {
  return (
    <div className="p-field">
      <span>
        <label
          htmlFor={props.name}
          className={classNames({
            "p-error": props.formFieldValid,
          })}
        >
          {props.label}
        </label>
        <InputText
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          autoFocus
          className={classNames({
            "p-invalid": props.formFieldValid,
          })}
          disabled = {props.disabled}
        />
      </span>
      {props.errors}
    </div>
  );
};

export default FormInputText;
