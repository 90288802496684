import React from "react";
import FormInputText from "../Form/FormInputText";
import FormDropdown from "../Form/FormDropdown";
import FormInputNumber from "../Form/FormInputNumber";
import DropdownCalendar from "../Form/FormCalendar";
import FormMultiSelect from "../Form/FormMultiSelect";
import ls from 'local-storage';
import "../../styles/SearchBar.css";
import {
  getFormErrorMessage,
  isFormFieldValid,
} from "./FormValidationNewPlayer";

const PlayerProfessionalFields = (props) => {
  return (
    <div>
      <FormInputNumber
        name="shirt_number"
        label="Numar tricou*"
        value={props.shirtNumber}
        onChange={props.setShirtNumber}
        errors={getFormErrorMessage(
          "shirt_number",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "shirt_number",
          props.touched,
          props.errors
        )}
        suffix=""
      />
      <FormInputText
        name="licence"
        label="Licenta"
        value={props.values.licence}
        onChange={props.onChange}
        errors={getFormErrorMessage("licence", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "licence",
          props.touched,
          props.errors
        )}
      />
      <FormInputText
        name="coach_history"
        label="Antrenori anteriori"
        value={props.values.coach_history}
        onChange={props.onChange}
        errors={getFormErrorMessage(
          "coach_history",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "coach_history",
          props.touched,
          props.errors
        )}
      />
      <FormInputText
        name="team_history"
        label="Echipe anterioare"
        value={props.values.team_history}
        onChange={props.onChange}
        errors={getFormErrorMessage(
          "team_history",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "team_history",
          props.touched,
          props.errors
        )}
      />
      {!props.addNew && (ls.get('role_id') === 5 || ls.get('role_id') === 4) &&
      <FormMultiSelect
        name="teams"
        label="Echipe"
        value={props.selectedTeams}
        options={props.allTeams}
        onChange={props.setSelectedTeams}
        optionLabel="label"
        placeholder="Selectati o echipa"
        display="chip"
        errors={getFormErrorMessage("teams", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "teams",
          props.touched,
          props.errors
        )}
        />
        }

      {props.addNew &&
        <FormMultiSelect
          name="teams"
          label="Echipe"
          value={props.selectedTeams}
          options={props.allTeams}
          onChange={props.setSelectedTeams}
          optionLabel="label"
          placeholder="Selectati o echipa"
          display="chip"
          errors={getFormErrorMessage("teams", props.touched, props.errors)}
          formFieldValid={isFormFieldValid(
            "teams",
            props.touched,
            props.errors
          )}
        />
        }
      <FormInputText
        name="injured"
        label="Accidentat"
        value={props.values.injured}
        onChange={props.onChange}
        errors={getFormErrorMessage("injured", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "injured",
          props.touched,
          props.errors
        )}
      />
      <FormDropdown
        name="play_post"
        label="Pozitie joc"
        value={props.values.play_post}
        onChange={props.onChange}
        options={props.allPlayPosts}
        optionLabel="play_post_desc"
        placeholder="Selectati postul preferat"
        errors={getFormErrorMessage("play_post", props.touched, props.errors)}
        formFieldValid={isFormFieldValid(
          "play_post",
          props.touched,
          props.errors
        )}
      />
      <FormDropdown
        name="preffered_foot"
        label="Picior preferat"
        value={props.values.preffered_foot}
        onChange={props.onChange}
        options={props.allPrefferedFoot}
        optionLabel="preffered_foot_desc"
        optionValue="value"
        placeholder="Selectati piciorul preferat"
        errors={getFormErrorMessage(
          "preffered_foot",
          props.touched,
          props.errors
        )}
        formFieldValid={isFormFieldValid(
          "preffered_foot",
          props.touched,
          props.errors
        )}
      />
      {props.addNew && (
        <div>
          <DropdownCalendar
            label="Data de inceput"
            name="start_date"
            value={props.startDate}
            onChange={props.setStartDate}
            errors={getFormErrorMessage(
              "start_date",
              props.touched,
              props.errors
            )}
            formFieldValid={isFormFieldValid(
              "start_date",
              props.touched,
              props.errors
            )}
          />
          <DropdownCalendar
            label="Data de sfarsit"
            name="end_date"
            value={props.endDate}
            onChange={props.setEndDate}
            errors={getFormErrorMessage(
              "end_date",
              props.touched,
              props.errors
            )}
            formFieldValid={isFormFieldValid(
              "end_date",
              props.touched,
              props.errors
            )}
          />
        </div>
      )}
    </div>
  );
};
export default PlayerProfessionalFields;
