import React, {useRef} from 'react';
import {useFormik} from "formik";
import {Panel} from "primereact/panel";
import {Password} from "primereact/password";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Accordion, AccordionTab} from "primereact/accordion";
import {
    createAcademy_Leader,
    createAdmin,
    createCoach, createPhysical_Trainer, createPsychologist, createUserTeamMap,
    getAllActiveStatus,
    getAllRoles,
    getAllUserTeamMapOfUserId, getFullUserDataByUserId, getAllUsers, getAllCoach,
    getAllAcademy_Leader, getAllPsychologist, getAllPhysical_Trainer
} from '../services/administration.service';
import {createTeam, getAllTeams} from '../services/team.service';
import {getAllPersons} from '../services/getAllPersons.service';
import {getAllPersonsWithUsersIds} from '../services/getPersonsByUserId.service';
import {showError, showSuccess, showWarnWithMessage} from "../services/toast.service";
import SkeletonUserCard from '../components/SkeletonUserCard';
import FormInputText from "../components/Form/FormInputText";
import UserCard from '../components/UserCard';
import TeamCard from "../components/TeamCard";
import FormMultiSelect from '../components/Form/FormMultiSelect';
import SkeletonTeamCard from "../components/SkeletonTeamCard";
import FormAccordionTabHeader from "../components/Form/FormAccordionTabHeader";
import FormDropdown from "../components/Form/FormDropdown";
import DropdownCalendar from "../components/Form/FormCalendar";
import FormPasswordFooter from "../components/Form/FormPasswordFooter";
import FormPasswordHeader from "../components/Form/FormPasswordHeader";
import {isFormValid, areFieldsValid, 
    isFormFieldValid, getFormErrorMessage, 
    formFields } from "../components/Form/FormValidationNewUser";
import { Nationalities} from '../components/Form/FormNationalities';
import AddImage from "../components/PlayerPage/AddImage";
import ls from 'local-storage';
import moment from 'moment';
import classNames from "classnames";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/Administration.css';
import '../styles/Common.css';
import '../styles/DropDownDemo.css';
import '../styles/MultiSelect.css'
import "../components/Form/Form.css";

export default function Administration() {
    const notificationRef = useRef(null);
    const [allUsers, setAllUsers] = React.useState([]);
    const [allTeams, setAllTeams] = React.useState([]);
    const [allUserTeams, setAllUserTeams] = React.useState([]);
    const [addUserDlg, setAddUserDlg] = React.useState(false);
    const [addTeamDlg, setAddTeamDlg] = React.useState(false);
    const [allRoles, setAllRoles] = React.useState([]);
    const [allActiveStatus, setAllActiveStatus] = React.useState([]);
    const [username, setUsername] = React.useState();
    const [password, setPassword] = React.useState();
    const [activeId, setActiveId] = React.useState(1);
    const [roleId, setRoleId] = React.useState({role_id: 5, role_desc: 'Admin'});
    const [firstname, setFirstname] = React.useState();
    const [lastname, setLastname] = React.useState();
    const [email, setEmail] = React.useState();
    const [phone, setPhone] = React.useState();
    const [pic_cnp, setPic_cnp] = React.useState();
    const [nationality, setNationality] = React.useState();
    const [birthdate, setBirthdate] = React.useState();
    const [profilePic, setProfilePic] = React.useState("https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png");
    const [full_address, setFull_address] = React.useState();
    const [team_history, setTeam_history] = React.useState();
    const [licence, setLicence] = React.useState();
    const [teamName, setTeamName] = React.useState(null);
    const [allPersons, setAllPersons] = React.useState(null);
    const [allCoaches, setAllCoaches] = React.useState(null);
    const [allAcademyLeaders, setAllAcademyLeaders] = React.useState(null);
    const [allPshychologists, setAllPshychologists] = React.useState(null);
    const [allPhysicalTrainers, setAllPhysicalTrainers] = React.useState(null);
    const isInitialMount = useRef(true);

    const formik = useFormik({
        initialValues: formFields,
        validate: (data) => {
          data.roleId=roleId.role_id;
          data.birthdate=birthdate;
          return areFieldsValid(data, true, getAllPersonsWithUsersIds(allPersons, allUsers));
        },
        onSubmit: (data) => {
          switch (roleId.role_id) {
            case 1:
                createCoach(ls.get('token'), getObjectToAdd())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                        else {
                            showSuccess(notificationRef);
                            addInTeamUserMap(res.data);
                            if(Number(formik.values.active_id.active_id) === 1)
                            {
                                addInAllUsersArrayAndReinit(res.data);
                            }
                            else
                            {
                                reinitAddUserFields();
                            }
                        }
                    }).catch(err => {
                        showError(notificationRef);
                        console.log(err);
                });
                break;
            case 2:
                createPhysical_Trainer(ls.get('token'), getObjectToAdd())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                        else {

                            showSuccess(notificationRef);
                            addInTeamUserMap(res.data);
                            if(Number(formik.values.active_id.active_id) === 1)
                            {
                                addInAllUsersArrayAndReinit(res.data);
                            }
                            else
                            {
                                reinitAddUserFields();
                            }
                        }
                    }).catch(err => {
                    showError(notificationRef);
                    console.log(err);
                });
                break;
            case 3:
                createPsychologist(ls.get('token'), getObjectToAdd())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                        else {
                            showSuccess(notificationRef);
                            addInTeamUserMap(res.data);
                            if(Number(formik.values.active_id.active_id) === 1)
                            {
                                addInAllUsersArrayAndReinit(res.data);
                            }
                            else
                            {
                                reinitAddUserFields();
                            }
                        }
                    }).catch(err => {
                    showError(notificationRef);
                    console.log(err);
                });
                break;
            case 4:
                createAcademy_Leader(ls.get('token'), getObjectToAdd())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                        else {
                            showSuccess(notificationRef);
                            addInTeamUserMap(res.data);
                            if(Number(formik.values.active_id.active_id) === 1)
                            {
                                addInAllUsersArrayAndReinit(res.data);
                            }
                            else
                            {
                                reinitAddUserFields();
                            }
                        }
                    }).catch(err => {
                    showError(notificationRef);
                    console.log(err);
                });
                break;
            case 5:
                createAdmin(ls.get('token'), getObjectToAdd())
                    .then((res) => {
                        if (res === null || res === undefined || res === '')
                            showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                        else {
                            showSuccess(notificationRef);
                            if(Number(formik.values.active_id.active_id) === 1)
                            {
                                addInAllUsersArrayAndReinit(res.data);
                            }
                            else
                            {
                                reinitAddUserFields();
                            }
                        }
                    }).catch(err => {
                    showError(notificationRef);
                    console.log(err);
                });
                break;
        }
          formik.resetForm();
          setAddUserDlg(false);
        },
      });

    React.useEffect(() => {
        const clonedAllRoles = [];
        const clonedAllActiveStatus = []
        var newTeams = [];
        var newUsers = [];
        var coaches = [];
        var pshychologists = [];
        var physical_trainers = [];
        var academy_leaders = [];
        if(isInitialMount.current){
            isInitialMount.current = false;
            getAllRoles(ls.get('token')).then(res => {
                if (res != null && res.length !== 0 && res[0] !== undefined) {
                    res.forEach(item => {
                        clonedAllRoles.push(item);
                    });
                    for (let i = 0; i < clonedAllRoles.length; i++) {
                        if (clonedAllRoles[i].role_id === 0) {
                            clonedAllRoles.splice(i, 1);
                            break;
                        }
                    }
                    setAllRoles(clonedAllRoles);
                }
            });
    
            getAllActiveStatus(ls.get('token')).then(res => {
                if (res != null && res.length !== 0 && res[0] !== undefined) {
                    res.forEach(item => {
                        clonedAllActiveStatus.push(item);
                    })
                    setAllActiveStatus(clonedAllActiveStatus);
                }
            });
    
            const persons = [];
            getAllPersons(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(person => {
                        persons.push(person);
                    });
                    setAllPersons(persons);
                }
            })
            .catch((err) => {
            console.log(err);
            });
    
            getAllUsers(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(user => {
                        newUsers.push(user);
                    });
                    setAllUsers(newUsers);
                }
            })
            .catch((err) => {
            console.log(err);
            });

            const userTeams = [];
            if(allUsers !== null && allUsers !==undefined){
                allUsers.forEach(user => {
                    getAllUserTeamMapOfUserId(ls.get('token'), user.user_id)
                    .then(res => {
                        if (res === null || res === undefined || res === '') {
                        } else {
                            res.forEach(item => {
                                userTeams.push(item.team_id);
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                });
                setAllUserTeams(userTeams);
            }
            
            getAllCoach(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(coach => {
                        coaches.push({person_id: coach.person_id, team_history: coach.team_history, licence: coach.licence});
                    });
                    setAllCoaches(coaches);
                }
            })
            .catch((err) => {
            console.log(err);
            });
    
            getAllAcademy_Leader(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(academy_leader => {
                        academy_leaders.push(academy_leader);
                    });
                    setAllAcademyLeaders(academy_leaders);
                }
            })
            .catch((err) => {
            console.log(err);
            });
    
            getAllPhysical_Trainer(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(physical_trainer => {
                        physical_trainers.push(physical_trainer);
                    });
                    setAllPhysicalTrainers(physical_trainers);
                }
                })
            .catch((err) => {
            console.log(err);
            });
    
            getAllPsychologist(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(psychologist => {
                        pshychologists.push(psychologist);
                    });
                    setAllPshychologists(pshychologists);
                }
                })
            .catch((err) => {
            console.log(err);
            });
    
            getAllTeams(ls.get('token'))
            .then(data => {
                if(data && data.length > 0 )
                {
                    data.forEach(team => {
                        newTeams.push(team);
                    });
                    setAllTeams(newTeams);
                }
            })
            .catch(err => {
                console.log(err);
            });
        }
        else
        {
            const persons = [];
            getAllPersons(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(person => {
                        persons.push(person);
                    });
                }
                if(JSON.stringify(persons) !== JSON.stringify(allPersons)){
                    setAllPersons(persons);
                }
            })
            .catch((err) => {
            console.log(err);
            });

            const coaches = [];
            getAllCoach(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(coach => {
                        coaches.push({person_id: coach.person_id, team_history: coach.team_history, licence: coach.licence});
                    });
                }
                if(JSON.stringify(coaches) !== JSON.stringify(allCoaches)){
                    setAllCoaches(coaches);
                    }
                })
            .catch((err) => {
            console.log(err);
            });

            const leaders = [];
            getAllAcademy_Leader(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(academy_leader => {
                        leaders.push(academy_leader);
                    });
                }
                if(JSON.stringify(leaders) !== JSON.stringify(allAcademyLeaders)){
                    setAllAcademyLeaders(leaders);
                    }
                })
            .catch((err) => {
            console.log(err);
            });

            const trainers = [];
            getAllPhysical_Trainer(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(physical_trainer => {
                        trainers.push(physical_trainer);
                    });
                }
                if(JSON.stringify(trainers) !== JSON.stringify(allPhysicalTrainers)){
                    setAllPhysicalTrainers(trainers);
                    }
                })
            .catch((err) => {
            console.log(err);
            });

            const pshychologists = [];
            getAllPsychologist(ls.get("token"))
            .then((data) => {
                if(data && data.length > 0 )
                {
                    data.forEach(psychologist => {
                        pshychologists.push(psychologist);
                    });
                }
                if(JSON.stringify(pshychologists) !== JSON.stringify(allPshychologists)){
                    setAllPshychologists(pshychologists);
                    }
                })
            .catch((err) => {
            console.log(err);
            });

            const teams = [];
            getAllTeams(ls.get('token'))
            .then(data => {
                if(data && data.length > 0 )
                {
                    data.forEach(team => {
                        teams.push(team);
                    });
                }
                if(JSON.stringify(teams) !== JSON.stringify(allTeams)){
                    setAllTeams(teams);
                }
            })
            .catch(err => {
                console.log(err);
            });

            const userTeams = [];
            if(allUsers !== null && allUsers !==undefined){
                allUsers.forEach(user => {
                    getAllUserTeamMapOfUserId(ls.get('token'), user.user_id)
                    .then(res => {
                        if (res === null || res === undefined || res === '') {
                        } else {
                            res.forEach(item => {
                                userTeams.push(item.team_id);
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                });
                if(JSON.stringify(userTeams) !== JSON.stringify(allUserTeams)){
                    setAllUserTeams(userTeams);
                }
            }
            
        }
    }, [allPersons, allTeams]);

    React.useEffect(() => {
        clearTimeout(ls.get('timeOut'));
    });

    const getObjectToAdd = () => {
        if (roleId !== 5)
            return {
                username: formik.values.username,
                password: formik.values.password,
                active_id: Number(formik.values.active_id.active_id),
                firstname: formik.values.firstname,
                lastname: formik.values.lastname,
                email: formik.values.email,
                phone: formik.values.phone,
                pic_cnp: formik.values.pic_cnp,
                nationality: formik.values.nationality.nationality_desc,
                birthdate: birthdate != null ? moment(birthdate).format() : null,
                full_address: formik.values.full_address,
                team_history: formik.values.teams_history,
                licence: formik.values.licence
            };

        else
            return {
                username: formik.values.username,
                password: formik.values.password,
                active_id: Number(formik.values.active_id.active_id),
                firstname: formik.values.firstname,
                lastname: formik.values.lastname,
                email: formik.values.email,
                phone: formik.values.phone,
                pic_cnp: formik.values.pic_cnp,
                nationality: formik.values.nationality.nationality_desc,
                birthdate: birthdate != null ? moment(birthdate).format() : null,
                full_address: formik.values.full_address,
            };
    }

    const addInTeamUserMap = (user_id) => {
        if(formik.values.teams !== null && formik.values.teams !== undefined)
        {
            formik.values.teams.forEach(team => {
                createUserTeamMap(ls.get('token'), getTeamUserMapObj(user_id, team.team_id))
                    .then(res => {
                        //showSuccess(notificationRef);
                    }).catch(err => {
                        showError(notificationRef);
                        console.log(err);
                    })
            });
        }
    }

    const reinitAddUserFields = () => {
        setUsername(null);
        setPassword(null);
        setRoleId(1);
        setActiveId(1);
        setFirstname(null);
        setLastname(null);
        setEmail(null);
        setPhone(null);
        setPic_cnp(null);
        setNationality(null);
        setFull_address(null);
        setBirthdate(null);
        setTeam_history(null);
        setLicence(null);
        setProfilePic("https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png");
    }

    const addInAllUsersArrayAndReinit = (user_id) => {
        const newUsers = allUsers;
        const newPersons = allPersons ? allPersons : [];
        getFullUserDataByUserId(ls.get('token'), user_id)
            .then(res => {
                const newUser = {active_id: res.active_id,
                    role_id: res.role_id,
                    user_id: res.user_id,
                    username: res.username}
                newUsers.push(newUser);
                setAllUsers(newUsers);

                const newPerson = {birthdate: res.birthdate,
                    email: res.email,
                    firstname: res.firstname,
                    full_address: res.full_address,
                    lastname: res.lastname,
                    nationality: res.nationality,
                    person_id: res.person_id,
                    phone: res.phone,
                    pic_cnp: res.pic_cnp,
                    user_id: res.user_id}
                newPersons.push(newPerson);
                setAllPersons(newPersons);

                if(res.role_id !== 5)
                {
                    addDetails(res, res.role_id);
                }

                reinitAddUserFields();
            }).catch(err => {
            showError(notificationRef);
            console.log(err);
        });
    }

    const addDetails = (userDetails, role_id) => 
    {
        var details = {person_id: userDetails.person_id,
            team_history: userDetails.team_history,
            licence: userDetails.licence}

        switch(role_id)
        {
            case 1: 
                const coaches = allCoaches === null ? [] : allCoaches;
                coaches.push(details);
                setAllCoaches(coaches);
            break;
            case 2: 
                const physical_trainers = allPhysicalTrainers === null ? [] : allPhysicalTrainers;
                physical_trainers.push(details);
                setAllPhysicalTrainers(physical_trainers);
            break;
            case 3: 
                const pshychologists = allPshychologists === null ? [] : allPshychologists;
                pshychologists.push(details);
                setAllPshychologists(pshychologists);
            break;
            case 4: 
                const academy_leaders = allAcademyLeaders === null ? [] : allAcademyLeaders;
                academy_leaders.push(details);
                setAllAcademyLeaders(academy_leaders);
            break;
        }
    }
    
    const reinitTeamFields = () => {
        setTeamName(null);
    }

    const getTeamObjToAdd = () => {
        return {
            team_name: teamName
        }
    }

    const execCreateTeam = () => {
        var isValidTeam = true;
        var newTeams = allTeams ? allTeams : [];
        allTeams.forEach(team => {
            if(team.team_name === teamName){
                isValidTeam = false;
            }
        });
        if(isValidTeam === false)
        {
            showWarnWithMessage(notificationRef, "Exista deja o echipa cu acest nume.");
        }
        else
        {
            if(teamName === null)
            {
                showWarnWithMessage(notificationRef, "Acest nume nu este valid.");
            }
            else if (teamName.match(/^ *$/) !== null)
            {
                showWarnWithMessage(notificationRef, "Acest nume nu este valid.");
            }
            else
            {
                createTeam(ls.get('token'), getTeamObjToAdd())
                .then((res) => {
                    if (res === null || res === undefined || res === '')
                        showWarnWithMessage(notificationRef, "Nu s-a putut adauga!");
                    else {
                        const newTeam = {team_id: res.team_id,
                            team_name: teamName};
                        newTeams.push(newTeam);
                        setAllTeams(newTeams);
                        setAddTeamDlg(true);
                        setAddTeamDlg(false);
                        showSuccess(notificationRef);
                    }
                }).catch(err => {
                    showError(notificationRef);
                    console.log(err);
                })
            }
        }
        reinitTeamFields();
    }

    const getTeamUserMapObj = (user_id, team_id) => {
        return {
            team_id: team_id,
            user_id: user_id
        }
    }

    const getProfessionalDetails = (role_id) => 
    {
        switch(role_id)
        {
            case 1: return allCoaches;
            case 2: return allPhysicalTrainers;
            case 3: return allPshychologists;
            case 4: return allAcademyLeaders;
        }
    }

    const setDetailsForNonAdminUsers = (role_id, newDetails) => 
    {
        switch(role_id)
        {
            case 1: setAllCoaches(newDetails);
                break;
            case 2: setAllPhysicalTrainers(newDetails);
                break;
            case 3: setAllPhysicalTrainers(newDetails);
                break;
            case 4: setAllAcademyLeaders(newDetails);
                break;
        }
    }

    return (
        <div className="Administration p-grid">
            <Toast ref={notificationRef} position="top-right"></Toast>
            <Panel header="Utilizatori" className="p-col-12">
                <div className="p-grid">
                    {
                        allUsers.map(user => {
                            var teams = [];
                            var person = [];
                            var professionalDetails = [];
                            var details = [];
                            if(user !== undefined && user.role_id > 0 && user.user_id > 1 && user.active_id !== 0){
                                if(allPersons !== null)
                                {
                                    if(allPersons.find(p => p.user_id === user.user_id) !== undefined)
                                    {
                                        person = allPersons.find(p => p.user_id === user.user_id);
                                        if(user.role_id !== 5)
                                        {
                                            var professionalDetails = getProfessionalDetails(user.role_id);
                                            if(professionalDetails !== null && professionalDetails !== undefined){
                                                if(professionalDetails.find(p=>p.person_id === person.person_id))
                                                {
                                                    details = professionalDetails.find(p=>p.person_id === person.person_id);
                                                }
                                            } 
                                        }
                                    }
                                }
                                if(allUserTeams.find(t=>t.user_id === user.user_id))
                                {
                                    teams = allUserTeams.find(t=>t.user_id === user.user_id);
                                }
                            return <UserCard userData={user} personData={person} notificationRef={notificationRef} key={user.user_id}
                                             allUsers={allUsers} setAllUsers={setAllUsers} allPersons={allPersons} setAllPersons={setAllPersons}
                                             userTeams={teams} allRoles={allRoles} allActiveStatus={allActiveStatus} professionalDetails={details}
                                             allTeams={allTeams} getAllDetails={getProfessionalDetails} setNewDetails={setDetailsForNonAdminUsers}/>
                            }
                        })

                    }

                    <SkeletonUserCard ClickFunction={() => setAddUserDlg(true)}/>
                </div>
            </Panel>
            <Panel header="Echipe" className="p-col-12">
                <div className="p-grid">
                    {allTeams.map(team => {
                        return <TeamCard team={team} allTeams={allTeams} setAllTeams={setAllTeams}
                                            key={team.team_id}
                                            notificationRef={notificationRef}/>})
                    }

                    <SkeletonTeamCard ClickFunction={() => setAddTeamDlg(true)}/>
                </div>
            </Panel>

            <Dialog header="Adauga Utilizator" visible={addUserDlg} modal closable={true}
                    className="p-fluid"
                    draggable={true}
                    onHide={() => setAddUserDlg(false)}>
                    <div className="p-d-flex p-jc-center">
                <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <Accordion activeIndex={0} style={{width: "50vw"}}>
                    <AccordionTab
                        header={
                        <FormAccordionTabHeader
                            icon="pi pi-user"
                            label="Utilizator"
                            isFormValid={isFormValid("user", formik.touched, formik.errors)}
                        />
                        }
                    >
                        <AddImage 
                            value={profilePic}
                            setImage={setProfilePic}
                            label="Adaugati poza de profil"
                        />
                        <FormInputText
                        name="username"
                        label="Nume utilizator*"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("username", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("username", formik.touched, formik.errors)}
                        />
                        <div className="p-field">
                        <span>
                            <label
                            htmlFor="password"
                            className={classNames({
                                "p-error": isFormFieldValid("password", formik.touched, formik.errors),
                            })}
                            >
                            Parola*
                            </label>
                            <Password
                            id="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            toggleMask
                            className={classNames({
                                "p-invalid": isFormFieldValid("password", formik.touched, formik.errors),
                            })}
                            header={<FormPasswordHeader/>}
                            footer={<FormPasswordFooter/>}
                            />
                        </span>
                        {getFormErrorMessage("password", formik.touched, formik.errors)}
                        </div>
                        <FormDropdown
                        name="active_id"
                        label="Tip Cont*"
                        value={formik.values.active_id}
                        onChange={formik.handleChange}
                        options={allActiveStatus}
                        optionLabel="active_desc"
                        placeholder="Selecteaza tipul"
                        errors={getFormErrorMessage("active_id", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("active_id", formik.touched, formik.errors)}
                        />
                        <FormDropdown
                        name="roleId"
                        label="Rol"
                        value={roleId}
                        onChange={e => setRoleId(e.value)}
                        options={allRoles}
                        optionLabel="role_desc"
                        placeholder="Selecteaza rolul"
                        errors={getFormErrorMessage("roleId", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("roleId", formik.touched, formik.errors)}
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={<FormAccordionTabHeader
                        icon="pi pi-phone"
                        label="Contact"
                        isFormValid={isFormValid("contact", formik.touched, formik.errors)}
                        />}
                    >
                        <FormInputText
                        name="firstname"
                        label="Prenume*"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("firstname", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("firstname", formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="lastname"
                        label="Nume*"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("lastname", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("lastname", formik.touched, formik.errors)}
                        />
                        <div className="p-field">
                        <span className="p-input-icon-right">
                            <label
                            htmlFor="email"
                            className={classNames({
                                "p-error": isFormFieldValid("email", formik.touched, formik.errors),
                            })}
                            >
                            Email*
                            </label>
                            <div className="p-inputgroup">
                            <InputText
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className={classNames({
                                "p-invalid": isFormFieldValid("email", formik.touched, formik.errors),
                                })}
                            />
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            </div>
                        </span>
                        {getFormErrorMessage("email", formik.touched, formik.errors)}
                        </div>
                        <FormInputText
                        name="phone"
                        label="Telefon*"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("phone", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("phone", formik.touched, formik.errors)}
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={<FormAccordionTabHeader
                        icon="pi pi-user-plus"
                        label="Date personale"
                        isFormValid={isFormValid("personal", formik.touched, formik.errors)}/>}
                    >
                        <FormInputText
                        name="pic_cnp"
                        label="CNP*"
                        value={formik.values.pic_cnp}
                        onChange={formik.handleChange}
                        errors={getFormErrorMessage("pic_cnp", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("pic_cnp", formik.touched, formik.errors)}
                        />
                        <FormDropdown
                        name="nationality"
                        label="Nationalitate"
                        value={formik.values.nationality}
                        onChange={formik.handleChange}
                        options={Nationalities}
                        optionLabel="nationality_desc"
                        placeholder="Selectati o nationalitate"
                        errors={getFormErrorMessage("nationality", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("nationality", formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="full_address"
                        label="Adresa"
                        value={formik.values.full_address}
                        onChange={formik.handleChange}
                        />
                        <DropdownCalendar 
                            name="birthdate"
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.value)}
                            label="Data nasterii"
                            errors={getFormErrorMessage("birthdate", formik.touched, formik.errors)}
                            formFieldValid={isFormFieldValid("birthdate", formik.touched, formik.errors)}
                        />
                    </AccordionTab>
                    <AccordionTab
                        header={ <FormAccordionTabHeader
                        icon="pi pi-briefcase"
                        label="Date profesionale"
                        isFormValid={roleId.role_id === 5 ? false : isFormValid("professional", formik.touched, formik.errors)}/>}
                        disabled = {roleId.role_id === 5}
                    >
                        <FormMultiSelect
                        name="teams"
                        label="Echipe"
                        value={formik.values.teams}
                        options={allTeams}
                        onChange={formik.handleChange}
                        optionLabel="team_name"
                        placeholder="Selectati o echipa"
                        display="chip"
                        errors={getFormErrorMessage("teams", formik.touched, formik.errors)}
                        formFieldValid={isFormFieldValid("teams",formik.touched, formik.errors)}
                        />
                        <FormInputText
                        name="teams_history"
                        label="Echipe anterioare"
                        value={formik.values.teams_history}
                        onChange={formik.handleChange}
                        />
                        <FormInputText
                        name="licence"
                        label="Licenta"
                        value={formik.values.licence}
                        onChange={formik.handleChange}
                        />
                    </AccordionTab>
                    </Accordion>
                    <Button
                        type="submit"
                        label="Adauga"
                        icon="pi pi-save"
                        className="p-mt-2"
                    />
                </form>
                </div>
            </div>
            </Dialog>
            
            <Dialog header="Adauga Echipa" visible={addTeamDlg} modal closable={true}
                    className="p-fluid"
                    draggable={true}
                    onHide={() => setAddTeamDlg(false)}>
                <div className="p-d-flex p-jc-center">
                    <div className="card">
                        <Accordion activeIndex={0} style={{width: "50vw"}}>
                            <AccordionTab header={<React.Fragment><i className="pi pi-users"></i><span> Echipa</span></React.Fragment>}>
                                <FormInputText
                                    name="teamName"
                                    label="Nume echipa*"
                                    value={teamName}
                                    onChange={(e) => setTeamName(e.target.value)}
                                />
                            </AccordionTab>
                        </Accordion>
                        <Button 
                            label="Adauga"
                            style={{ width: "50vw" }}
                            icon="pi pi-save"
                            className="p-mt-2"
                            onClick={() => {
                                execCreateTeam();
                                setAddTeamDlg(false);
                            }}/>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}