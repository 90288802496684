import React, { useState } from "react";
import { Avatar } from "primereact/avatar";
import "./AddImage.css";

export const AddImage = (props) => {
  var [blobUrl, setBlobUrl] = useState(null);
  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = Buffer.toString(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const imageHandler = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        props.setImage(reader.result);
        //props.setImage(b64toBlob(reader.result.substring(reader.result.indexOf(',')), reader.result.substring(5,reader.result.indexOf(';'))));
        //setBlobUrl(URL.createObjectURL(b64toBlob(reader.result.substring(reader.result.indexOf(',')), reader.result.substring(5,reader.result.indexOf(';')))))
        // console.log(reader.result.substring(reader.result.indexOf(',')+1));
        // console.log(b64toBlob(reader.result.substring(reader.result.indexOf(',')), reader.result.substring(5,reader.result.indexOf(';'))));
        // console.log(URL.createObjectURL(b64toBlob(reader.result.substring(reader.result.indexOf(',')), reader.result.substring(5,reader.result.indexOf(';')))))
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div>
      <label className="p-center">{props.label}</label>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/apng"
        name="image-upload"
        id="input"
        onChange={imageHandler}
      />
      <div className="label">
        <label htmlFor="input">
          <Avatar
            image={props.value}
            className="mr-2"
            size="xlarge"
            shape="square"
          />
        </label>
      </div>
    </div>
  );
};

export default AddImage;
