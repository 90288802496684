export const SetPlayerErrors = (errors, value) => {
    errors.birthdate = value;
    errors.coach_history = value;
    errors.email = value;
    errors.end_date = value;
    errors.firstname = value;
    errors.full_address = value;
    errors.height = value;
    errors.injured = value;
    errors.lastname = value;
    errors.licence = value;
    errors.password = value;
    errors.phone = value;
    errors.pic_cnp = value;
    errors.play_post = value;
    errors.preffered_foot = value;
    errors.shirt_number = value;
    errors.start_date = value;
    errors.team_history = value;
    errors.teams = value;
    errors.username = value;
    errors.weight = value;
    errors.nationality = value;

    return errors;
};