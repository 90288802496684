import React from "react";
import ContactSubtable from "./ContactSubtable";
import ProfessionalSubtable from "./ProfessionalSubtable";

const PlayerSubtables = (props) => {
  return (
    <div className="card">
      <p>Profilul jucatorului</p>
      <ContactSubtable className={props.className} value={props.value} />
      <ProfessionalSubtable className={props.className} value={props.value} />
    </div>
  );
};
export default PlayerSubtables;
