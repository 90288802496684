import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import '../../styles/Table.css';
import '../../styles/Players.css';

const PlayersTable = (props) => {
  return (
    <div>
        <DataTable
          value={props.tableValue}
          expandedRows={props.tableExpandedRows}
          onRowToggle={props.tableOnRowToggle}
          responsiveLayout="scroll"
          rowExpansionTemplate={props.tableRowExpansionTemplate}
          dataKey="user_id"
          globalFilter={props.tableGlobalFilter}
          className="p-datatable-responsive-demo"
          style={{marginLeft:"4.5%"}}
        >
          <Column expander style={{ width: "3em" }} />
          <Column field="name" header="Nume" sortable />
          <Column body={props.imageBodyTemplate} />
          <Column field="age" header="Varsta" sortable />
          <Column field="birthdate_formatted" header="Data Nasterii" sortable />
          <Column field="nationality" header="Nationalitate" sortable />
          <Column field="shirt_number" header="Numar" sortable />
          <Column field="play_post.play_post_desc" header="Pozitie" sortable />
          <Column field="injured" header="Accidentat" sortable />
          <Column
            body={props.tableActionsBody}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
  );
};
export default PlayersTable;
