import React from "react";
import AddNewPlayerButton from "./AddNewPlayerButton";
import PlayerFilterOperations from "./PlayerFilterOperations";
import '../../styles/Table.css';
import '../../styles/Players.css';

const PlayerOperations = (props) => {
  return (
    <div>
        <PlayerFilterOperations 
            onInputSearch={props.onInputSearch}
            teamFilterValue={props.teamFilterValue}
            teamFilterOnChange={props.teamFilterOnChange}
            teamFilterOptions={props.teamFilterOptions}/>
        <AddNewPlayerButton onClick={props.addNewPlayerOnClick}/>
    </div>
  );
};
export default PlayerOperations;
