import React from "react";
import { Dialog } from "primereact/dialog";
import { Accordion } from "primereact/accordion";
import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import PlayerContactFields from "./PlayerContactFields";
import PlayerProfessionalFields from "./PlayerProfessionalFields";
import PlayerPersonalFields from "./PlayerPersonalFields";
import PlayerUserFields from "./PlayerUserFields";
import FormAccordionTabHeader from "../Form/FormAccordionTabHeader";
import { isFormValid } from "./FormValidationNewPlayer";

const PlayerAddEditDialog = (props) => {
  return (
    <Dialog
      visible={props.visible}
      header={props.addNew ? "Adaugare Jucator" : "Modificare Jucator"}
      modal
      className="p-fluid"
      // footer={playerDialogFooter(hideDialog, savePlayer)}
      onHide={props.onHide}
    >
      <div className="p-d-flex p-jc-center">
        <div className="card">
          <form onSubmit={props.handleSubmit} className="p-fluid">
            <Accordion activeIndex={0} style={{ width: "50vw" }}>
              <AccordionTab
                header={
                  <FormAccordionTabHeader
                    icon="pi pi-user"
                    label="Utilizator"
                    isFormValid={
                      props.addNew &&
                      isFormValid("user", props.touched, props.errors)
                    }
                  />
                }
              >
                <PlayerUserFields
                  profilePicValue={props.profilePicValue}
                  setProfilePic={props.setPlayerProfilePic}
                  addNew={props.addNew}
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  onChange={props.onChange}
                />
              </AccordionTab>
              <AccordionTab
                header={
                  <FormAccordionTabHeader
                    icon="pi pi-phone"
                    label="Contact"
                    isFormValid={isFormValid(
                      "contact",
                      props.touched,
                      props.errors
                    )}
                  />
                }
              >
                <PlayerContactFields
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  onChange={props.onChange}
                />
              </AccordionTab>
              <AccordionTab
                header={
                  <FormAccordionTabHeader
                    icon="pi pi-user-plus"
                    label="Date personale"
                    isFormValid={isFormValid(
                      "personal",
                      props.touched,
                      props.errors
                    )}
                  />
                }
              >
                <PlayerPersonalFields
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  onChange={props.onChange}
                  playerWeight={props.playerWeight}
                  setPlayerWeight={props.setPlayerWeight}
                  playerHeight={props.playerHeight}
                  setPlayerHeight={props.setPlayerHeight}
                  playerBirthdate={props.playerBirthdate}
                  setPlayerBirthdate={props.setPlayerBirthdate}
                  addNew = {props.addNew}
                />
              </AccordionTab>
              <AccordionTab
                header={
                  <FormAccordionTabHeader
                    icon="pi pi-briefcase"
                    label="Date profesionale"
                    isFormValid={isFormValid(
                      props.addNew ? "professional" : "professional_update",
                      props.touched,
                      props.errors
                    )}
                  />
                }
              >
                <PlayerProfessionalFields
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  onChange={props.onChange}
                  addNew={props.addNew}
                  shirtNumber={props.shirtNumber}
                  setShirtNumber={props.setShirtNumber}
                  allTeams={props.allTeams}
                  selectedTeams={props.selectedTeams}
                  setSelectedTeams={props.setSelectedTeams}
                  allPlayPosts={props.allPlayPosts}
                  allPrefferedFoot={props.allPrefferedFoot}
                  startDate={props.startDate}
                  setStartDate={props.setStartDate}
                  endDate={props.endDate}
                  setEndDate={props.setEndDate}
                />
              </AccordionTab>
            </Accordion>
            <Button
              type="submit"
              label={props.addNew ? "Adauga Jucator" : "Modifica Jucator"}
              icon="pi pi-save"
              className="p-mt-2"
              style={{ width: "50vw" }}
            />
          </form>
        </div>
      </div>
    </Dialog>
  );
};
export default PlayerAddEditDialog;
