import React from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";

const FormDropdown = (props) => {
  return (
    <div className="p-field">
      <span>
        <label
          htmlFor={props.name}
          className={classNames({
            "p-error": props.formFieldValid,
          })}
        >
          {props.label}
        </label>
        <Dropdown
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          options={props.options}
          optionLabel={props.optionLabel}
          placeholder={props.placeholder}
          className={classNames({
            "p-invalid": props.formFieldValid,
          })}
          disabled = {props.disabled}
        />
        {props.errors}
      </span>
    </div>
  );
};

export default FormDropdown;
