import { Nationalities } from "./FormNationalities";

export let getNationality = (nationalityToGet) => {
    var nationalityToReturn = {nationality_id: 1, nationality_desc: 'Afgan'};
    Nationalities.forEach((nationality) => 
    {
      if(nationality.nationality_desc === nationalityToGet)
      {
        nationalityToReturn = nationality;
      }
    });
    return nationalityToReturn;
  };