import React from "react";
import { MultiSelect } from "primereact/multiselect";
import classNames from "classnames";

const FormMultiSelect = (props) => {
  return (
    <div className="p-field">
      <span>
      <label
          htmlFor={props.name}
          className={classNames({
            "p-error": props.formFieldValid
          })}
        >
          {props.label}
        </label>
        <MultiSelect
          id={props.name}
          name={props.name}
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          optionLabel={props.optionLabel}
          placeholder={props.placeholder}
          display={props.display}
          className={classNames({
            "p-invalid": props.formFieldValids
          })}
        />
        {props.errors}
      </span>
    </div>
  );
};

export default FormMultiSelect;
