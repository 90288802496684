import React from "react";
import FormDropdown from "../Form/FormDropdown";
import SearchBar from "./SearchBar";
import '../../styles/Table.css';
import '../../styles/Players.css';

const PlayerFilterOperations = (props) => {
  return (
    <div className="operations">
        <SearchBar onInput={props.onInputSearch} placeholder="Cautati..."/>
        <FormDropdown
            name="filter_team"
            value={props.teamFilterValue}
            onChange={props.teamFilterOnChange}
            options={props.teamFilterOptions}
            optionLabel="label"
            placeholder="Alegeti o echipa"
        /> 
    </div>
  );
};
export default PlayerFilterOperations;
