import React from "react";
import { Dialog } from "primereact/dialog";

const PlayerDeleteDialog = (props) => {
  return (
    <Dialog
      visible={props.visible}
      style={{ width: "450px" }}
      header="Confirmare"
      modal
      footer={props.footer}
      onHide={props.onHide}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {props.player && (
          <span>
            Sunteti sigur ca vreti sa stergeti jucatorul <b>{props.player.name}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
export default PlayerDeleteDialog;
