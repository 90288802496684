import { checkPassword } from "./CheckPassword";
export const isFormValid = (type, touched, errors) => {
  switch (type) {
    case "user":
      return (
        !!(touched["username"] && errors["username"]) ||
        !!(touched["active_id"] && errors["active_id"]) ||
        !!(touched["password"] && errors["password"])
      );
    case "contact":
      return (
        !!(touched["lastname"] && errors["lastname"]) ||
        !!(touched["firstname"] && errors["firstname"]) ||
        !!(touched["email"] && errors["email"]) ||
        !!(touched["phone"] && errors["phone"])
      );
    case "personal":
      return (
        !!(touched["pic_cnp"] && errors["pic_cnp"]) ||
        !!(touched["nationality"] && errors["nationality"])
      );
    case "professional":
      return (
        !!(touched["teams"] && errors["teams"])
      );
  }
};

export const areFieldsValid = (data, isNew, persons) => {
  let errors = {};
  if (!data.username) {
    errors.username = "Numele de utilizator trebuie completat.";
  }
  if(isNew){
    if (!data.password) {
      errors.password = "Parola trebuie completata.";
    }
    else{
      if (data.password.length < 8) {
        errors.password = "Parola trebuie sa contina minim 8 caractere.";
      }
      if (!checkPassword(data.password)) {
        errors.password = "Parola trebuie sa contina cel putin o majuscula si o cifra.";
      }
    }
  }

  if (!data.active_id) {
    errors.active_id = "Tipul de cont trebuie selectat.";
  }

  if (!data.roleId) {
    errors.roleId = "Rolul trebuie selectat.";
  }

  if(!data.birthdate){
    errors.birthdate = "Data nasterii trebuie completata.";
  }

  if (!data.firstname) {
    errors.firstname = "Prenumele trebuie completat.";
  }

  if (!data.lastname) {
    errors.lastname = "Numele trebuie completat.";
  }
  if (!data.teams && !isNew) {
    errors.teams = "Selectati cel putin o echipa.";
  }

  if (!data.phone) {
    errors.phone = "Numarul de telefon trebuie completat.";
  }
  else{
    let isNumber = /^\d+$/.test(data.phone);
    if (isNumber === false || data.phone.length !== 10 || !data.phone.startsWith('07')){
      errors.phone = "Numarul trebuie sa contina 10 cifre si sa fie de forma 07XXXXXXXX";
    }
  }

  if (!data.nationality) {
    errors.nationality = "Nationalitatea trebuie selectata.";
  }

  let control_cnp =
    data.pic_cnp[0] * 2 +
    data.pic_cnp[1] * 7 +
    data.pic_cnp[2] * 9 +
    data.pic_cnp[3] * 1 +
    data.pic_cnp[4] * 4 +
    data.pic_cnp[5] * 6 +
    data.pic_cnp[6] * 3 +
    data.pic_cnp[7] * 5 +
    data.pic_cnp[8] * 8 +
    data.pic_cnp[9] * 2 +
    data.pic_cnp[10] * 7 +
    data.pic_cnp[11] * 9;

  let isNumber = /^\d+$/.test(data.pic_cnp);
  if (!data.pic_cnp) {
    errors.pic_cnp = "CNP-ul trebuie completat.";
  } else if(isNumber === false){
    errors.pic_cnp = "CNP-ul trebuie sa contina doar cifre.";
  }
  else if (data.pic_cnp.length !== 13) {
    errors.pic_cnp = "CNP-ul trebuie sa contina exact 13 cifre.";
  } else if (
    (Number(data.pic_cnp[0]) > 6 || Number(data.pic_cnp[0]) < 1) &&
    Number(data.pic_cnp[0]) !== 9
  ) {
    errors.pic_cnp = "CNP-ul nu este valid.";
  } else if (control_cnp % 11 !== Number(data.pic_cnp[12])) {
    if (!(control_cnp % 11 === 10 && Number(data.pic_cnp[12]) === 1)) {
      errors.pic_cnp = "CNP-ul nu este valid.";
    }
  }

  if (!data.email) {
    errors.email = "Email trebuie completat.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
    errors.email = "Adresa de email invalida. Ex: exemplu@email.com";
  }
  if(isNew)
  {
    persons.forEach((person) => {
      if(person.username === data.username){
        errors.username = "Username-ul exista deja in baza de date.";
      }

      if(person.email === data.email){
        errors.email = "Email-ul exista deja in baza de date.";
      }

      if(person.phone === data.phone){
        errors.phone = "Numarul de telefon exista deja in baza de date.";
      }

      if(person.pic_cnp === data.pic_cnp){
        errors.pic_cnp = "CNP-ul exista deja in baza de date.";
      }
    });
  }
  
  return errors;
};

export const isFormFieldValid = (name, touched, errors) => {
  return !!(touched[name] && errors[name]);
};

export const getFormErrorMessage = (name, touched, errors) => {
  return (
    isFormFieldValid(name, touched, errors) && (
      <small className="p-error">{errors[name]}</small>
    )
  );
};

export const formFields = {
  username: "",
  password: "",
  active_id: null,
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  pic_cnp: "",
  nationality: null,
  full_address: "",
  birthdate: null,
  licence: "",
  teams_history: null,
  teams: null,
};
