export const emptyPlayer = {
    user_id: 0,
    person_id: 0,
    player_id: 0,
    username: "",
    password: "",
    name: "",
    firstname: "",
    lastname: "",
    image: null,
    birthdate: 0,
    birthdate_formatted: "",
    age: 0,
    nationality: "",
    shirt_number: 0,
    play_post: null,
    injured: "",
    pic_cnp: "",
    email: "",
    phone: "",
    full_address: "",
    licence: "",
    weight: 0,
    height: 0,
    preffered_foot: null,
    team_history: "",
    coach_history: "",
    start_date: "",
    end_date: "",
    teams: null
  };
  //format birthdate field
  export const fields = [
    "user_id",
    "person_id",
    "player_id",
    "username",
    "password",
    "name",
    "firstname",
    "lastname",
    "image",
    "birthdate",
    "birthdate_formatted",
    "age",
    "nationality",
    "shirt_number",
    "play_post",
    "injured",
    "pic_cnp",
    "email",
    "phone",
    "full_address",
    "licence",
    "weight",
    "height",
    "preffered_foot",
    "team_history",
    "coach_history",
    "start_date",
    "end_date",
    "teams"
  ];
  export const empty = {
    user_id: 0,
    person_id: 0,
    player_id: 0,
    username: "",
    password: "",
    name: "",
    firstname: "",
    lastname: "",
    image: null,
    birthdate: 0,
    birthdate_formatted: "",
    age: 0,
    nationality: "",
    shirt_number: 0,
    play_post: null,
    injured: "",
    pic_cnp: "",
    email: "",
    phone: "",
    full_address: "",
    licence: "",
    weight: 0,
    height: 0,
    preffered_foot: null,
    team_history: "",
    coach_history: "",
    start_date: "",
    end_date: "",
    teams: null
  };