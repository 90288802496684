import React from "react";
import "../styles/Players.css";
import PlayerPage from "../components/Table";

export default function Players() {    
    return (
      <div id="players" className="Players">
        <div className="data">
          <PlayerPage
            className="custom-table"
          />
        </div>
      </div>
    );
}
