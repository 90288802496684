import {getDefaultHeader} from '../constants/RESTApiData.js';
import axiosInstance from "../helpers/axios";

export const getAllUsers = async (token) => {
    return await axiosInstance.get('/users/getAll', {
        headers: getDefaultHeader(token)
    })
    .then(response => {
       return response.data;
     }); 
};

export const getAllAdmin = async (token) => {
    try {
        const res = await axiosInstance.get('/admin/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const getAllAcademy_Leader = async (token) => {
    try {
        const res = await axiosInstance.get('/academy_leader/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const getAllPhysical_Trainer = async (token) => {
    try {
        const res = await axiosInstance.get('/physical_trainer/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const getAllCoach = async (token) => {
    try {
        const res = await axiosInstance.get('/coach/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const getAllPsychologist = async (token) => {
    try {
        const res = await axiosInstance.get('/psychologist/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

/*
 * By deleting from users table,
 * you will also delete from person and role table,
 * since it is on delete cascade
 */
export const deleteUser = async (token, user_id) => {
    try {
        const res = await axiosInstance.delete('/users/delete/' + user_id, {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const updateUserActiveId = async (token, user_id, active_id) => {
    try {
        const res = await axiosInstance.get('/users/changestatus/' + user_id + '/' + active_id, {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const updateAdmin = (token, obj) => {
    return axiosInstance.put('/admin/update', obj, {
        headers: getDefaultHeader(token)
    });
}

export const updateAcademy_Leader = (token, obj) => {
    return axiosInstance.put('/academy_leader/update', obj, {
        headers: getDefaultHeader(token)
    });
}

export const updatePhysical_Trainer = (token, obj) => {
    return axiosInstance.put('/physical_trainer/update', obj, {
        headers: getDefaultHeader(token)
    });
}
export const updateCoach = (token, obj) => {
    return axiosInstance.put('/coach/update', obj, {
        headers: getDefaultHeader(token)
    });
}

export const updatePsychologist = (token, obj) => {
    return axiosInstance.put('/psychologist/update', obj, {
        headers: getDefaultHeader(token)
    });
}

export const getAllRoles = async (token) => {
    try {
        const res = await axiosInstance.get('/t_role/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
};

export const getAllActiveStatus = async (token) => {
    try {
        const res = await axiosInstance.get('/t_active/getAll', {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
};

export const createAdmin = (token, obj) => {
    return axiosInstance.post('/admin/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const createAcademy_Leader = (token, obj) => {
    return axiosInstance.post('/academy_leader/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const createPhysical_Trainer = (token, obj) => {
    return axiosInstance.post('/physical_trainer/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const createCoach = (token, obj) => {
    return axiosInstance.post('/coach/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const createPsychologist = (token, obj) => {
    return axiosInstance.post('/psychologist/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const getFullUserDataByUserId = async (token, user_id) => {
    try {
        const res = await axiosInstance.get('/users/getFullDataUserByUserId/' + user_id, {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const createUserTeamMap = (token, obj) => {
    return axiosInstance.post('/usermap/create', obj, {
        headers: getDefaultHeader(token)
    });
}

export const getAllUserTeamMapOfUserId = async (token, user_id) => {
    try {
        const res = await axiosInstance.get('/usermap/getAllOf/' + user_id, {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const deleteAllOfUser = async (token, user_id) => {
    try {
        const res = await axiosInstance.delete('/usermap/deleteAllOfUser/' + user_id, {
            headers: getDefaultHeader(token)
        });
        return res.data;
    } catch (e) {
        console.log(e);
    }
}

export const changeUserPassword = (token, userObj) => {
    return axiosInstance.post('/users/change-password', userObj, {
        headers: getDefaultHeader(token)
    });
}