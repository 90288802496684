import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ProfessionalSubtable = (props) => {
  return (
    <div>
      <DataTable className={props.className} value={props.value} style={{marginLeft:"4.5%"}} responsiveLayout="stack">
        <Column className="column" field="weight" header="Greutate (kg)"/>
        <Column className="column" field="height" header="Inaltime (cm)"/>
        <Column className="column" field="preffered_foot.preffered_foot_desc" header="Picior Preferat"/>
        <Column className="column" field="team_history" header="Echipe Anterioare"/>
        <Column className="column" field="coach_history" header="Antrenori Anteriori"/>
      </DataTable>
      <br />
    </div>
  );
};
export default ProfessionalSubtable;
